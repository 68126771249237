import type { components } from '~/schemas/achievements';
import type { Reward } from '~/stores/achievements/types/common';

import { achievementsService } from '~/services/achievementsService';

export function convertMissionReward(mission: components['schemas']['UserMissionProgressDto']) {
    const data = mission.mission;
    if (data.rewards) {
        return convertReward(data.rewards.map(reward => reward.reward));
    }

    return { items: [] };
}

export function convertAchievementReward(achievement: components['schemas']['UserAchievementStateDto']) {
    const data = achievement.achievement;
    if (data.rewards) {
        return convertReward(data.rewards.map(reward => reward.reward));
    }

    return { items: [] };
}

export function convertReward(rewards: components['schemas']['RewardViewDto'][]) {
    const result: Reward = {
        items: [],
    };

    for (const reward of rewards) {
        if (reward.items) {
            result.items.push(...reward.items.map((item) => ({
                id: item.itemClassAlias,
                iconSrc: achievementsService.getItemImageUrl(item.itemClassAlias),
                name: item.name,
                description: item.description,
                amount: item.amount,
            })));
        }

        if (reward.points) {
            result.points ??= 0;
            result.points += reward.points;
        }

        if (reward.pointsMultiplier) {
            result.pointsMultiplier ??= 0;
            result.pointsMultiplier += reward.pointsMultiplier.value;

            result.pointsMultiplierComponents ??= [];
            result.pointsMultiplierComponents.push(reward.pointsMultiplier);
        }

        if (reward.badges) {
            result.badges ??= 0;
            result.badges += reward.badges;
        }
    }

    return result;
}
