import type { UseMouseEventExtractor } from '@vueuse/core';

export const mouseExtractor: UseMouseEventExtractor = event => (
    event instanceof Touch
        ? null
        : [event.offsetX, event.offsetY]
);

export function throwIfNull<T>(value: NonNullable<T> | null | undefined, message?: string): NonNullable<T> {
    if (value === null || value === undefined) {
        throw new Error(message);
    }

    return value;
}

export async function wait(timeout: number): Promise<boolean> {
    return new Promise(resolve => setTimeout(() => resolve(true), timeout));
}

export async function retry<T>(
    fn: () => Promise<T>,
    conditions?: (params: T) => boolean,
    { count, timeout = 0 }: {
    count: number;
    timeout?: number;
  } = { count: 1 },
): Promise<T> {
    let retries = 0;
    let result: T;
    while (retries < count) {
        const isLastRetry = retries + 1 >= count;

        try {
            result = await fn();
            if (!conditions || conditions(result)) {
                return result;
            }
        } catch (error) {
            if (isLastRetry) {
                throw error;
            }
        }

        if (isLastRetry) {
            break;
        }

        await wait(timeout);
        retries++;
    }

    throw new Error('Retry limit exceeded');
}

export function ensureArray<T>(values: T | T[]) {
    return Array.isArray(values) ? values : [values];
}

export function formatNumber(number: number, maxLength = 2) {
    return number.toString().padStart(maxLength, '0');
}

export const enumerate = <T>(items: T[]) => items.map((item, index) => [item, index] as const);

export function isDefined<T>(value: T | undefined | null): value is T {
    return value !== undefined && value !== null;
}

export function passIfDefined<T, U>(val: T | undefined | null, func: (_: T) => U) {
    return (isDefined(val) ? func(val) : val) ?? undefined;
}

export function makePromise<T = void>(): [Promise<T>, (value: T | PromiseLike<T>) => void, (reason?: unknown) => void] {
    let outerResolve: ((value: T | PromiseLike<T>) => void) | undefined = undefined;
    let outerReject: ((reason?: unknown) => void) | undefined = undefined;

    const promise = new Promise<T>((resolve, reject) => {
        outerResolve = resolve;
        outerReject = reject;
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return [promise, outerResolve!, outerReject!];
}

export function clamp(min: number, max: number, value: number): number {
    return Math.min(max, Math.max(min, value));
}

export function randomElement<T>(array: T[]): T {
    return array[Math.floor(Math.random() * array.length)];
}

export const floor = (value: number, length = 0) => {
    const modifier = 10 ** length;

    return Math.floor(value * modifier) / modifier;
};

export const round = (value: number, length = 0) => {
    const modifier = 10 ** length;

    return Math.round(value * modifier) / modifier;
};

export function formatAddress(address: string) {
    return address.slice(0, 6) + '..' + address.slice(-4);
}

const minuteCount = 60;
const hourCount = 60;
const dayCount = 24;

const secondLength = 1000;
const minuteLength = secondLength * minuteCount;
const hourLength = minuteLength * hourCount;
const dayLength = hourLength * dayCount;

const dateFull = {
    days: (time: number) => Math.floor(time / dayLength),
    hours: (time: number) => Math.floor(time / hourLength),
    minutes: (time: number) => Math.floor(time / minuteLength),
    seconds: (time: number) => Math.floor(time / secondLength),
};

const date = {
    days: dateFull.days,
    hours: (time: number) => dateFull.hours(time) - dateFull.days(time) * dayCount,
    minutes: (time: number) => dateFull.minutes(time) - dateFull.hours(time) * hourCount,
    seconds: (time: number) => dateFull.seconds(time) - dateFull.minutes(time) * minuteCount,
    milliseconds: (time: number) => time - dateFull.seconds(time) * secondLength,
};

export const getTime = (timespan: number) => {
    const days = date.days(timespan);
    const hours = date.hours(timespan);
    const minutes = date.minutes(timespan);
    const seconds = date.seconds(timespan);
    const milliseconds = date.milliseconds(timespan);

    return { days, hours, minutes, seconds, milliseconds };
};

export function formatTimeLeft(timestampDiff: number) {
    const { days, hours, minutes, seconds } = getTime(timestampDiff);

    return (days ? `${days}D ` : '') + `${hours}H ${minutes}M ${seconds}S`;
}
