import type { components } from '~/schemas/achievements';
import type { Referral } from '~/stores/achievements/types/referrals';
import dayjs from 'dayjs';

export function convertReferrals(
    nodes: components['schemas']['ReferralTreeNodeDto'][],
): Referral[] {

    const myFirstLevelReferrals = nodes.filter(referral => referral.referralLevel === 1)
        .sort((a, b) => dayjs(a.joinedAt).isAfter(dayjs(b.joinedAt)) ? 1 : -1);

    const myThirdPartyReferrals = nodes.filter(referral => referral.referralLevel !== 1 && referral.referralLevel !== 0);

    return achievements.invites.map((item, index) => {

        const { reward, inviteNumber } = item;

        const referralData = index < myFirstLevelReferrals.length ? myFirstLevelReferrals[index] : undefined;

        const multiplier = reward.pointsMultiplier?.value ?? 0;
        const points = reward.points ?? 0;

        if (!referralData) {
            return {
                multiplier,
                points,
                level: inviteNumber ?? index + 1,

                data: {
                    type: 'placeholder',
                },
            };
        }

        return {
            multiplier,
            points,
            level: inviteNumber ?? index + 1,

            data: {
                type: 'user',
                userId: referralData.userId,
                name: referralData.visibleUsername ?? referralData.userId,
                avatarUrl: '/images/avatars/mid.png', // referralData.avatarUrl,
                pointsEarned: myThirdPartyReferrals.filter(item => item.referrerUserId === referralData.userId)
                    .reduce((acc, curr) => acc + curr.pointsEarned, 0) + referralData.pointsEarned,
            },
        };
    });
}
