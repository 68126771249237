import { default as icons_45page39hbHjG23OMeta } from "/home/runner/work/77bit-site-v6/77bit-site-v6/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as accountzJUcOyRDAYMeta } from "/home/runner/work/77bit-site-v6/77bit-site-v6/pages/account.vue?macro=true";
import { default as awakening_45missionsO2gRL4VvR4Meta } from "/home/runner/work/77bit-site-v6/77bit-site-v6/pages/awakening-missions.vue?macro=true";
import { default as battle_45passtZzCJNh7psMeta } from "/home/runner/work/77bit-site-v6/77bit-site-v6/pages/battle-pass.vue?macro=true";
import { default as boostswksMkkqFgMeta } from "/home/runner/work/77bit-site-v6/77bit-site-v6/pages/boost.vue?macro=true";
import { default as dailyccdgNswYhvMeta } from "/home/runner/work/77bit-site-v6/77bit-site-v6/pages/daily.vue?macro=true";
import { default as indexwzcj1rKlDFMeta } from "/home/runner/work/77bit-site-v6/77bit-site-v6/pages/index.vue?macro=true";
import { default as invitesA8eISOoPa5Meta } from "/home/runner/work/77bit-site-v6/77bit-site-v6/pages/invites.vue?macro=true";
import { default as refundgZrPyZIGCnMeta } from "/home/runner/work/77bit-site-v6/77bit-site-v6/pages/refund.vue?macro=true";
import { default as storeB5UxvbtjAnMeta } from "/home/runner/work/77bit-site-v6/77bit-site-v6/pages/store.vue?macro=true";
export default [
  {
    name: icons_45page39hbHjG23OMeta?.name ?? "icons-page",
    path: icons_45page39hbHjG23OMeta?.path ?? "/_icons",
    meta: {...(icons_45page39hbHjG23OMeta || {}), ...{"layout":"svg-sprite"}},
    alias: icons_45page39hbHjG23OMeta?.alias || [],
    redirect: icons_45page39hbHjG23OMeta?.redirect || undefined,
    component: () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: accountzJUcOyRDAYMeta?.name ?? "account",
    path: accountzJUcOyRDAYMeta?.path ?? "/account",
    meta: accountzJUcOyRDAYMeta || {},
    alias: accountzJUcOyRDAYMeta?.alias || [],
    redirect: accountzJUcOyRDAYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/pages/account.vue").then(m => m.default || m)
  },
  {
    name: awakening_45missionsO2gRL4VvR4Meta?.name ?? "awakening-missions",
    path: awakening_45missionsO2gRL4VvR4Meta?.path ?? "/awakening-missions",
    meta: awakening_45missionsO2gRL4VvR4Meta || {},
    alias: awakening_45missionsO2gRL4VvR4Meta?.alias || [],
    redirect: awakening_45missionsO2gRL4VvR4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/pages/awakening-missions.vue").then(m => m.default || m)
  },
  {
    name: battle_45passtZzCJNh7psMeta?.name ?? "battle-pass",
    path: battle_45passtZzCJNh7psMeta?.path ?? "/battle-pass",
    meta: battle_45passtZzCJNh7psMeta || {},
    alias: battle_45passtZzCJNh7psMeta?.alias || [],
    redirect: battle_45passtZzCJNh7psMeta?.redirect || undefined,
    component: () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/pages/battle-pass.vue").then(m => m.default || m)
  },
  {
    name: boostswksMkkqFgMeta?.name ?? "boost",
    path: boostswksMkkqFgMeta?.path ?? "/boost",
    meta: boostswksMkkqFgMeta || {},
    alias: boostswksMkkqFgMeta?.alias || [],
    redirect: boostswksMkkqFgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/pages/boost.vue").then(m => m.default || m)
  },
  {
    name: dailyccdgNswYhvMeta?.name ?? "daily",
    path: dailyccdgNswYhvMeta?.path ?? "/daily",
    meta: dailyccdgNswYhvMeta || {},
    alias: dailyccdgNswYhvMeta?.alias || [],
    redirect: dailyccdgNswYhvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/pages/daily.vue").then(m => m.default || m)
  },
  {
    name: indexwzcj1rKlDFMeta?.name ?? "index",
    path: indexwzcj1rKlDFMeta?.path ?? "/",
    meta: indexwzcj1rKlDFMeta || {},
    alias: indexwzcj1rKlDFMeta?.alias || [],
    redirect: indexwzcj1rKlDFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invitesA8eISOoPa5Meta?.name ?? "invites",
    path: invitesA8eISOoPa5Meta?.path ?? "/invites",
    meta: invitesA8eISOoPa5Meta || {},
    alias: invitesA8eISOoPa5Meta?.alias || [],
    redirect: invitesA8eISOoPa5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/pages/invites.vue").then(m => m.default || m)
  },
  {
    name: refundgZrPyZIGCnMeta?.name ?? "refund",
    path: refundgZrPyZIGCnMeta?.path ?? "/refund",
    meta: refundgZrPyZIGCnMeta || {},
    alias: refundgZrPyZIGCnMeta?.alias || [],
    redirect: refundgZrPyZIGCnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/pages/refund.vue").then(m => m.default || m)
  },
  {
    name: storeB5UxvbtjAnMeta?.name ?? "store",
    path: storeB5UxvbtjAnMeta?.path ?? "/store",
    meta: storeB5UxvbtjAnMeta || {},
    alias: storeB5UxvbtjAnMeta?.alias || [],
    redirect: storeB5UxvbtjAnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/pages/store.vue").then(m => m.default || m)
  }
]