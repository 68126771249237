<script setup lang="ts">
import { computed, watch } from 'vue';

const props = withDefaults(
    defineProps<{
        type?: 'once' | 'loop' | 'rotate';
        customProgress?: number; // 0..1
        animationDuration?: number;
        defaultStrokeWidth?: number;
        defaultRadius?: number;
        backgroundColor?: string;
    }>(),
    {
        type: 'loop',
        customProgress: undefined,
        animationDuration: .5,
        defaultStrokeWidth: 5,
        defaultRadius: 20,
        backgroundColor: undefined,
        disableAnimation: false,
    },
);

const emit = defineEmits<{
    (event: 'complete', payload: void): void;
}>();

const size = computed(() => (props.defaultRadius + props.defaultStrokeWidth) * 2);
const radiusSizePx = computed(() => props.defaultRadius);
const strokeWidthSizePx = computed(() => props.defaultStrokeWidth);

watch(
    () => props.customProgress,
    () => {
        if (props.customProgress === 1) {
            onComplete();
        }
    },
);

const dashArraySize = computed(() => props.defaultRadius * 2 * Math.PI);

const onComplete = () => {
    emit('complete');
};
</script>

<template>
    <div class="spinner">
        <svg
            class="spinner__inner"
            :class="[`spinner__inner_${type}`]"
            :viewBox="`0 0 ${size} ${size}`"
            :style="{
                width: `calc(${size}px * var(--scale))`,
                height: `calc(${size}px * var(--scale))`,
            }"
        >
            <!-- bg -->
            <circle
                v-if="backgroundColor"
                class="spinner__bg-path"
                :cx="size / 2"
                :cy="size / 2"
                :r="radiusSizePx"
                fill="none"
                :stroke-width="strokeWidthSizePx"
                :stroke="backgroundColor"
            />

            <circle
                class="spinner__path"
                :class="{
                    'spinner__path_anim': customProgress === undefined,
                }"
                :cx="size / 2"
                :cy="size / 2"
                :r="radiusSizePx"
                fill="none"
                :stroke-width="strokeWidthSizePx + 2"
                stroke="currentColor"
                :style="{
                    ['--dash-array-size']: dashArraySize,
                    ['--progress']: (customProgress ?? 0) * dashArraySize,
                    ['--animation-duration']: `${animationDuration}s`,
                    ['--animation-iteration-count']: type === 'loop' ? 'infinite' : '1',
                }"
                @animationend="onComplete"
                @animationiteration="onComplete"
            />
        </svg>
    </div>
</template>

<style scoped lang="scss">
@keyframes fill-circle {
  0% {
    stroke-dasharray: 0, var(--dash-array-size);
  }
  100% {
    stroke-dasharray: var(--dash-array-size), var(--dash-array-size);
  }
}

.spinner {
    &__inner {
        @keyframes spin {
            100% {
                transform:rotate(360deg);
            }
        }

        position: relative;

        &_once,
        &_loop {
            transform: rotate(-90deg);
        }

        &_rotate {
            animation: spin 1s linear infinite;
        }
    }

    &__path {
        stroke-dasharray: var(--progress), var(--dash-array-size);

        &_anim {
            animation: fill-circle var(--animation-duration) linear var(--animation-iteration-count) forwards;
        }
    }

    &__bg-path {
        position: absolute;
        top: 0;
        left: 0;

        z-index: -1;
    }
}
</style>
