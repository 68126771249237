import { ref } from 'vue';

import type { components } from '~/schemas/inventory';
import eventService from '~/services/eventService';
import { inventoryService } from '~/services/inventoryService';

export interface InventoryData {
    items: components['schemas']['InventoryEntry'][];
}

export const useInventoryStore = defineStore('inventory', () => {
    const { inventoryApiHost } = storeToRefs(useRemoteConfigStore());
    const { isLoggedIn } = storeToRefs(useAuthStore());

    const trackingItems = [
        'ronin_classic',
        CURRENCIES.BITS,
        '4e92939db34989f8', // battle pass
        'b20e5880f0c11932', // bit bot
    ];

    watch(isLoggedIn, isLoggedIn => {
        if (isLoggedIn) {
            if (!inventoryApiHost.value) {
                throw new Error('Achievements host is not defined');
            }

            inventoryService.init(inventoryApiHost.value);
        }
    });

    const isInited = ref(false);

    eventService.inventory.on('init', async () => {
        isInited.value = inventoryService.isInited;
    });
    eventService.premiumStore.on('purchase', async () => {
        fetchInventory();
    });

    const inventory = reactive<InventoryData>({ items: [] });

    const fetchInventory = async () => {
        const inventoryRaw: components['schemas']['InventoryEntry'][] = [];

        for (const item of trackingItems) {
            const entries = await inventoryService.getInventory(item);

            if (entries)
                inventoryRaw.push(...entries);
        }

        inventory.items = inventoryRaw;
    };


    return {
        isInited,

        inventory,

        fetchInventory,
    };
});
