import type { components } from '~/schemas/achievements';
import type { AwakeningMission } from '~/stores/achievements/types/awakeningMissions';
import { convertMissionReward } from '~/stores/achievements/common';

export function convertAwakeningMissions(
    missions: components['schemas']['UserMissionProgressDto'][],
    dailyMissions: components['schemas']['UserDailyMissionProgressDto'][],
): AwakeningMission[] {
    const result: AwakeningMission[] = [];

    const getType = (typeString: string) => {
        switch (typeString) {
            case 'DAY': return 'day';
            case 'LIMITED': return 'limited';
            case 'INFINITE': return 'infinite';
            default: return undefined;
        }
    };

    const dayPrefix = `${ACHIEVEMENT_GROUPS.AWAKENING}_DAY_`;

    const allMissions = [
        ...missions
            .sort((a, b) => {
                const aTag = getTagData(a.mission.tags, dayPrefix);
                const bTag = getTagData(b.mission.tags, dayPrefix);

                const aNum = aTag ? parseInt(aTag) : 0;
                const bNum = bTag ? parseInt(bTag) : 0;

                return aNum - bNum;
            }),
        ...dailyMissions,
    ];

    for (const [mission, index] of enumerate(allMissions)) {
        const data = mission.mission;

        const tagType = getTagData(data.tags, `${ACHIEVEMENT_GROUPS.AWAKENING}_TYPE_`);
        if (!tagType) {
            console.error('No tag type data found for mission', data.id);
            continue;
        }

        const type = getType(tagType);
        if (!type) {
            console.error('Unknown mission type', data.id, tagType);
            continue;
        }

        const missionReward = convertMissionReward(mission);

        result.push({
            id: data.id,
            code: (type === 'limited' || type === 'infinite') ? type : `${index + 1}`,
            title: data.name,
            description: data.description,
            reward: missionReward,
            isAvailable: mission.isAvailable,
            type,
            action: getAwakeningAction(data),
            extraText: (type === 'limited' || type === 'infinite') ? mission.mission.name : undefined,
            target: mission.target,
            progress: mission.progress,
        });
    }

    return result;
}
