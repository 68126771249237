import {
    Wallet,
    AuthClient,
    AuthSession,
    ProfileManagement,
    TelegramAuthConfig,
    TwitterAuthConfig,
} from '@nord-beaver/77bit-auth-sdk';

const TG_BOT_NAME = 'seven_seven_bit_reffering_bot';
const TG_BOT_ID = 7403740390;

export enum SdkLoginType {
    Telegram = 'telegram',
    Twitter = 'twitter',
    Wallet = 'wallet',
    Email = 'email',
}

export type SdkLoginConfig = {
    loginType: SdkLoginType.Telegram;
    refCode?: string;
} | {
    loginType: SdkLoginType.Twitter;
    refCode?: string;
} | {
    loginType: SdkLoginType.Wallet;
    address?: string;
    refCode?: string;
} | {
    loginType: SdkLoginType.Email;
    email: string;
    code: string;
};

export type SdkBindConfig = {
    loginType: SdkLoginType.Telegram;
} | {
    loginType: SdkLoginType.Twitter;
} | {
    loginType: SdkLoginType.Wallet;
    address: string;
} | {
    loginType: SdkLoginType.Email;
    email: string;
    code: string;
};

export class AuthSdkProvider {
    private readonly authClient: AuthClient;

    private profileManagement?: ProfileManagement;
    private session: AuthSession | null = null;
    private wallet?: Wallet;
    private _loginType?: AuthSession['loggedInWith'];

    // TODO: improve?
    public errorCode: string | null = null;

    onAuthSuccess?: () => void;
    onAuthLogout?: () => void;
    onBindSuccess?: () => void;
    onWalletConnect?: () => void;

    constructor(authUrl: string) {
        this.authClient = new AuthClient({
            authUrl,
            telegramAuthConfig: {
                embedded: {
                    botName: TG_BOT_NAME,
                    returnTo: window.origin + '/',
                    buttonSize: 'medium',
                    requestSendingMessagesViaBot: true,
                },
                redirect: {
                    botId: TG_BOT_ID,
                    returnTo: window.origin + '/',
                    requestSendingMessagesViaBot: true,
                },
            },
            twitterAuthConfig: {
                returnTo: window.origin + '/',
            },
        });
    }

    get isLogged() { return !!this.session; }
    get accessToken() { return this.session?.accessToken; }
    get loginType() { return this._loginType; }

    get connectedAddresses() { return this.wallet?.accounts; }
    get loginAddress() { return this.session?.loggedInWith.ethAddress; }

    get userInfo() { return this.session?.userInfo; }

    async init() {
        try {
            this.session = await this.authClient.getSession();
            if (this.session) {
                this._loginType = this.session.loggedInWith;

                await this.refreshProfileManagement();

                this.onAuthSuccess?.();
            }

            // try {
            //     const accounts = await window.ethereum?.request<string[]>({ method: 'eth_accounts' });

            //     if (accounts && accounts.length > 0) {
            //         this.wallet = await Wallet.connect('metamask');
            //         this.onWalletConnect?.();
            //     }
            // } catch (e) {
            //     console.warn('Failed to connect to metamask', e);
            // }
        } catch (e) {
            console.warn('No session found', e);
        }

        // if current url is /account
        if (window.location.pathname === '/account')
            this.errorCode = this.authClient.extractAuthErrorCodeFromLocation();

        if (this.errorCode)
            console.log('Extracted error code:', this.errorCode);
    }

    async login(config: SdkLoginConfig) {
        const { loginType } = config;

        switch (loginType) {
            case SdkLoginType.Telegram: {
                const { refCode } = config;

                this.authClient.telegramAuth.loginWithTelegram(refCode);
                break;
            }

            case SdkLoginType.Twitter: {
                const { refCode } = config;

                this.authClient.twitterAuth.loginWithTwitter(refCode);
                break;
            }

            case SdkLoginType.Wallet: {
                const { refCode } = config;
                let { address } = config;

                this.wallet = await this.connectWallet();

                // TODO: store last address in local storage
                if (!address) {
                    if (this.wallet.accounts.length === 0) {
                        throw new Error('Address is required for wallet login');
                    }

                    address = this.wallet.accounts[0];
                }

                await this.authClient.ethAuth.loginWithEthAddress(address, this.wallet, refCode);
                this.session = await this.authClient.getSession();

                break;
            }

            case SdkLoginType.Email: {
                const { email, code } = config;

                await this.authClient.emailAuth.loginWithEmail(email, code);
                this.session = await this.authClient.getSession();
                break;
            }
        }

        if (this.session) {
            this._loginType = this.session.loggedInWith;

            await this.refreshProfileManagement();

            this.onAuthSuccess?.();
        }
    }

    async logout() {
        await this.authClient.logout();
        this.session = null;
        this._loginType = undefined;
        this.wallet?.removeAccountsChangedListener();
        this.wallet = undefined;

        this.onAuthLogout?.();
    }

    async bind(config: SdkBindConfig) {
        await this.refreshProfileManagement();

        const profile = this.profileManagement;
        if (!profile) {
            throw new Error('Profile management is not initialized');
        }

        const { loginType } = config;
        switch (loginType) {
            case SdkLoginType.Telegram: {
                profile.bindTelegram();
                break;
            }

            case SdkLoginType.Twitter: {
                profile.bindTwitter();
                break;
            }

            case SdkLoginType.Wallet: {
                const { address } = config;

                this.wallet = await this.connectWallet();

                if (!this.wallet.accounts.includes(address)) {
                    throw new Error('Address is not in metamask');
                }

                await profile.bindEthAddress(address, this.wallet);
                break;
            }

            case SdkLoginType.Email: {
                const { email, code } = config;

                await profile.bindEmail(email, code);
                break;
            }
        }

        await this.refreshSession();

        this.onBindSuccess?.();
    }

    async refreshSession() {
        console.log('Refreshing session');

        this.session = await this.authClient.forceRefreshSession();
        return !!this.session;
    }

    async refreshProfileManagement() {
        console.log('Refreshing profile management');

        this.profileManagement = await this.authClient.profileManagement();

        console.log('Profile management refreshed');
    }

    // utils

    async connectWallet() {
        if (!this.wallet)
            this.wallet = await Wallet.connect('metamask');

        this.wallet.removeAccountsChangedListener();
        this.wallet.setAccountsChangedListener(async accounts => {
            console.log('Metamask accounts changed:', accounts);

            if (this.isLogged) {
                await this.refreshSession();
                this.onWalletConnect?.();

                if (this.loginAddress && (!accounts.includes(this.loginAddress))) {
                    await this.logout();
                }
            } else if (accounts.length === 0) {
                window.location.reload();
            }
        });

        this.onWalletConnect?.();

        return this.wallet;
    }

    isWalletConnected() {
        return !!this.wallet;
    }

    async sendEmailOtp(email: string, refCode?: string) {
        console.log('Sending email otp: ', email);

        return await this.authClient.emailAuth.sendEmailOtp(email, refCode);
    }

    async sendBindEmailOtp(email: string) {
        await this.refreshProfileManagement();

        if (!this.profileManagement) {
            throw new Error('Profile management is not initialized');
        }

        console.log('Sending email otp for binding: ', email);

        await this.profileManagement.sendOtpForEmailBinding(email);

        console.log('Email otp sent');
    }

    changeTelegramReturnTo(returnTo: string) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const config = this.authClient.telegramAuth.telegramAuthConfig as TelegramAuthConfig | undefined;

        if (config?.redirect) config.redirect.returnTo = returnTo;
        if (config?.embedded) config.embedded.returnTo = returnTo;
    }

    changeTwitterReturnTo(returnTo: string) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const config = this.authClient.twitterAuth.twitterAuthConfig as TwitterAuthConfig | undefined;

        if (config) config.returnTo = returnTo;
    }
}
