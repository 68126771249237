import createClient, { type Middleware } from 'openapi-fetch';
import type { paths } from '~/schemas/inventory';
import { authService } from '~/services/authService';
import eventService from '~/services/eventService';

export class InventoryService {
    private _client?: ReturnType<typeof createClient<paths>>;
    private _isInited = false;

    private get client() {
        return throwIfNull(this._client, 'Client is not initialized');
    }

    get isInited() {
        return this._isInited;
    }

    async init(host: string) {
        const authMiddleware: Middleware = {
            async onRequest({ request }) {
                const accessToken = authService.accessToken;
                if (!accessToken) {
                    throw new Error('Access token is not defined');
                }

                request.headers.set('Authorization', `Bearer ${accessToken}`);

                return request;
            },
        };

        const baseUrl = this.buildUrl(host, true);

        this._client = createClient<paths>({ baseUrl });
        this._client.use(authMiddleware);

        this._isInited = true;
        eventService.inventory.emit('init');
    }

    getInventory = async (itemClassId?: string) =>
        (await this.client?.GET('/inventory', { params: { query: { itemClassId } } }))?.data;

    private buildUrl(host: string, useSSL: boolean = true) {
        return `${useSSL ? 'https' : 'http'}://${host}/api`;
    }
}

export const inventoryService = new InventoryService();
