import type { components } from '~/schemas/achievements';
import type { DailyReward } from '~/stores/achievements/types/daily';
import dayjs from 'dayjs';
import { convertMissionReward } from '~/stores/achievements/common';

export function convertDailyRewards(
    missions: components['schemas']['UserMissionProgressDto'][],
): DailyReward[] {
    const datePrefix = `${ACHIEVEMENT_GROUPS.DAILY_LOGIN}_DATE_`;

    return missions
        .sort((a, b) => {
            const aTag = getTagData(a.mission.tags, datePrefix)?.replaceAll('_', '-');
            const bTag = getTagData(b.mission.tags, datePrefix)?.replaceAll('_', '-');

            const aDate = aTag ? dayjs(aTag) : undefined;
            const bDate = bTag ? dayjs(bTag) : undefined;

            return aDate?.diff(bDate) ?? 0;
        })
        .map(mission => {
            const data = mission.mission;

            if (mission.mission.rewards?.length !== 1) {
                console.warn('Unexpected rewards count for mission', mission);
            }

            const reward = convertMissionReward(mission);

            if (reward.items.length > 1) {
                console.warn('Unexpected items count for mission', mission);
            }

            let mode = 'item' as DailyReward['mode'];
            if (reward.items.length === 0) {
                if (reward.points) {
                    mode = 'points';
                } else if (reward.pointsMultiplier) {
                    mode = 'pointsMultiplier';
                } else if (reward.badges) {
                    mode = 'badges';
                }
            }

            return {
                id: data.id,
                data: reward,
                mode,
                isPremium: data.tags.includes(`${ACHIEVEMENT_GROUPS.DAILY_LOGIN}_MILESTONE`),
                target: mission.target,
                progress: mission.progress,
            } satisfies DailyReward;
        });
}
