import { storeToRefs } from 'pinia';
import { authService, UserInfo } from '~/services/authService';
import eventService from '~/services/eventService';
import { useRemoteConfigStore } from '~/stores/remote-config';

// wrapper around auth service
export const useAuthStore = defineStore('auth', () => {
    const { authHost, authApiHost, isLoaded } = storeToRefs(useRemoteConfigStore());

    watch(
        isLoaded,
        isLoadedRemoteConfig => {
            if (!isLoadedRemoteConfig) return;

            authService.init({
                host: authHost.value,
                apiHost: authApiHost.value,
            });
        },
        { immediate: true },
    );

    const isInited = ref(false);
    const isLoggedIn = ref(false);

    const connectedAddresses = ref<string[]>([]);
    const blockchainAddress = ref<string | undefined>(undefined); // logged in blockchain address
    const userInfo = ref<UserInfo>({
        walletAddresses: [],
    });

    eventService.auth.on('inited', () => {
        isInited.value = authService.isInited;
    });

    eventService.auth.on('login', async () => {
        isLoggedIn.value = authService.isLogged;
        blockchainAddress.value = authService.loginAddress;
        userInfo.value = authService.userInfo;
    });

    eventService.auth.on('logout', () => {
        isLoggedIn.value = false;
        blockchainAddress.value = undefined;
    });

    eventService.auth.on('connectWallet', () => {
        if (authService.connectedAddresses)
            connectedAddresses.value = authService.connectedAddresses;
    });

    eventService.auth.on('bind', () => {
        userInfo.value = authService.userInfo;
    });

    return {
        isInited,
        isLoggedIn,
        connectedAddresses,
        blockchainAddress,
        userInfo,
    };
});
