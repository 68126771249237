import type { Reward } from '~/stores/achievements/types/common';
import type { components } from '~/schemas/achievements';
import { achievementsService } from '~/services/achievementsService';

export function convertAssignedRewards(
    assignedRewards: components['schemas']['AssignedRewardDto'][],
): Reward {
    if (assignedRewards.length === 0) {
        throw new Error('No assigned rewards found');
    }

    const result: Reward = {
        items: [],
    };

    for (const reward of assignedRewards) {
        switch (reward.content?.type) {
            case 'ITEMS': {
                const content = reward.content as components['schemas']['ItemsAssignedRewardContentDto'];

                result.items.push(...content.items.map((item) => ({
                    id: item.itemClassAlias,
                    iconSrc: achievementsService.getItemImageUrl(item.itemClassAlias),
                    name: item.name,
                    description: item.description,
                    amount: item.amount,
                })));

                break;
            }

            case 'POINTS': {
                const content = reward.content as components['schemas']['PointsAssignedRewardContentDto'];

                result.points ??= 0;
                result.points += content.amount ?? 0;

                break;
            }

            case 'BADGES': {
                const content = reward.content as components['schemas']['BadgesAssignedRewardContentDto'];

                result.badges ??= 0;
                result.badges += content.number ?? 0;

                break;
            }

            case 'POINTS_MULTIPLIER': {
                const content = reward.content as components['schemas']['PointsMultiplierAssignedRewardContentDto'];

                result.pointsMultiplier ??= 0;
                result.pointsMultiplier += content.value ?? 0;

                break;
            }
        }
    }

    return result;
}
