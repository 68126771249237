import type { components } from '~/schemas/achievements';
import type { Phase } from '~/stores/achievements/types/battlePass';
import { convertMissionReward } from '~/stores/achievements/common';
import dayjs, { type Dayjs } from 'dayjs';

export function convertBattlePass(
    missions: components['schemas']['UserMissionProgressDto'][],
): Phase[] {
    const result: Phase[] = [];

    const phaseMissions: Record<number, {
        missions: { date: Dayjs; mission: components['schemas']['UserMissionProgressDto'] & { dateCode: string } }[];
        bonusMission?: components['schemas']['UserMissionProgressDto'] & { dateCode: string };
    }> = {};

    const premiumPhaseMissions: Record<number, {
        missions: { date: Dayjs; mission: components['schemas']['UserMissionProgressDto'] & { dateCode: string }  }[];
        bonusMission?: components['schemas']['UserMissionProgressDto'] & { dateCode: string };
    }> = {};

    for (const mission of missions) {
        const phase = getTagData(mission.mission.tags, `${ACHIEVEMENT_GROUPS.BATTLE_PASS}_PHASE_`);
        if (!phase || isNaN(parseInt(phase))) {
            console.error('No phase data found for mission', mission.mission.id);
            continue;
        }
        const phaseIndex = parseInt(phase);

        const phaseConfig = getBattlePassPhaseConfig(phaseIndex);
        if (!phaseConfig) {
            console.error('No phase config found for mission', mission.mission.id);
            continue;
        }

        const dateCode = getTagData(mission.mission.tags, `${ACHIEVEMENT_GROUPS.BATTLE_PASS}_MISSION_`);
        if (!dateCode) {
            console.error('No date code data found for mission', mission.mission.id);
            continue;
        }

        const dateString = dateCode.replaceAll('_', '-');

        const isPremium = mission.mission.tags.includes(`${ACHIEVEMENT_GROUPS.BATTLE_PASS}_PREMIUM`);
        const container = isPremium ? premiumPhaseMissions : phaseMissions;

        let phaseData = container[phaseIndex];
        if (phaseData === undefined) {
            phaseData = container[phaseIndex] = {
                missions: [],
                bonusMission: undefined,
            };
        }

        if (dateCode === 'BONUS') {
            if (phaseData.bonusMission) {
                console.error('Duplicate bonus mission found for phase', phaseIndex);
                continue;
            }

            phaseData.bonusMission = { ...mission, dateCode };
        } else {
            const date = dayjs(dateString);
            if (!date.isValid()) {
                console.error('Invalid date string', dateString);
                continue;
            }

            phaseData.missions.push({ date, mission: { ...mission, dateCode } });
        }
    }

    for (const phaseId in phaseMissions) {
        const phaseIndex = parseInt(phaseId);
        if (!premiumPhaseMissions[phaseIndex]) {
            console.error('No premium missions found for phase', phaseId);
            continue;
        }

        const phaseData = phaseMissions[phaseIndex];
        const phasePremiumData = premiumPhaseMissions[phaseIndex];

        if (phaseData.missions.length === 0 || phasePremiumData.missions.length === 0) {
            console.error('No missions found for phase', phaseId);
            continue;
        }

        const config = getBattlePassPhaseConfig(phaseIndex);
        if (!config) {
            console.error('No phase config found for phase', phaseId);
            continue;
        }

        const bonusMission = phaseData.bonusMission;
        const premiumBonusMission = phasePremiumData.bonusMission;
        if (!bonusMission || !premiumBonusMission) {
            console.error('No bonus mission found for phase', phaseId);
            continue;
        }

        const missions = phaseData.missions
            .sort((a, b) => a.date.diff(b.date))
            .map(({ mission }) => mission);

        const premiumMissions = phasePremiumData.missions
            .sort((a, b) => a.date.diff(b.date))
            .map(({ mission }) => mission);

        const getClaimId = (mission: components['schemas']['UserMissionProgressDto']) => {
            return mission.mission.completionCondition.sourceType === 'CLIENT_EVENT'
                ? (mission.mission.completionCondition as components['schemas']['InstantMissionConditionDto']).payload?.claimId
                : undefined;
        };

        result.push({
            ...config,
            missions: missions.map((mission, index) => ({
                id: mission.mission.id,
                premiumId: premiumMissions[index].mission.id,
                name: mission.mission.name,
                description: mission.mission.description,
                reward: convertMissionReward(mission),
                premiumReward: convertMissionReward(premiumMissions[index]),
                isAvailable: mission.isAvailable,
                target: mission.target,
                progress: mission.progress,
                dateCode: mission.dateCode,
                claimId: getClaimId(mission),
            })),
            bonusMission: {
                id: bonusMission.mission.id,
                premiumId: premiumBonusMission.mission.id,
                name: bonusMission.mission.name,
                description: bonusMission.mission.description,
                reward: convertMissionReward(bonusMission),
                premiumReward: convertMissionReward(premiumBonusMission),
                isAvailable: bonusMission.isAvailable,
                target: bonusMission.target,
                progress: bonusMission.progress,
                dateCode: bonusMission.dateCode,
                claimId: getClaimId(bonusMission),
            },
            phaseEndTimestamp: phaseData.missions[phaseData.missions.length - 1].date.endOf('day').valueOf(),
        });
    }

    return result;
}
