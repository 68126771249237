import { getRemoteConfig } from '~/utils/remoteConfig/getRemoteConfig';

export const useRemoteConfigStore = defineStore('remoteConfig', () => {
    const isLoaded = ref(false);
    const authHost = ref<string | undefined>(undefined);
    const authApiHost = ref<string | undefined>(undefined);
    const achievementsHost = ref<string | undefined>(undefined);
    const premiumStoreHost = ref<string | undefined>(undefined);
    const inventoryApiHost = ref<string | undefined>(undefined);
    const cloudflareAccountHash = ref<string | undefined>(undefined);
    const homeUrl = ref<string | undefined>(undefined);

    getRemoteConfig().then(config => {
        if (!config) {
            console.error('Unable to fetch remote config');
            return;
        }

        authHost.value = config.authHost;
        authApiHost.value = config.authApiHost;
        achievementsHost.value = config.achievementsHost;
        premiumStoreHost.value = config.premiumStoreHost;
        inventoryApiHost.value = config.inventoryApiHost;
        cloudflareAccountHash.value = config.cloudflareAccountHash;
        homeUrl.value = config.homeUrl;
        isLoaded.value = true;
    });

    return {
        authHost,
        authApiHost,
        achievementsHost,
        premiumStoreHost,
        inventoryApiHost,
        cloudflareAccountHash,
        homeUrl,
        isLoaded,
    };
});
