/* eslint-disable max-len */
import type { components } from '~/schemas/achievements';
import type { Text } from '~/types/text';
import type { BoostLevel } from '~/stores/achievements/types/boost';
import type { Reward } from '~/stores/achievements/types/common';
import { achievementsService } from '~/services/achievementsService';

export enum BOOST_TYPE {
    COUNTER = 'COUNTER',
    ITEMS = 'ITEMS',
}

export enum COUNTER {
    POINTS_EARNED = 'POINTS_EARNED',
    BADGES_EARNED = 'BADGES_EARNED',
    REFERRALS_NUMBER = 'REFERRALS_NUMBER',
    GAME_LOGIN_DAYS_IN_ROW = 'GAME_LOGIN_DAYS_IN_ROW',
    UNIQUE_LOGIN_DAYS = 'UNIQUE_LOGIN_DAYS',
    UNIQUE_GAME_LOGIN_DAYS = 'UNIQUE_GAME_LOGIN_DAYS',
}

export enum ACHIEVEMENT_GROUPS {
    DAILY_LOGIN = 'DAILY_LOGIN',
    AWAKENING = 'AWAKENING',
    BOOST = 'BOOST',
    BATTLE_PASS = 'BATTLE_PASS',
    ITEM = 'ITEM',
    INVITES = 'INVITES',
    ENDY_GROUP = 'ENDY_GROUP',
    ADDITIONAL_ACHIEVEMENTS = 'ADDITIONAL_ACHIEVEMENTS',
    TUTORIAL = 'TUTORIAL',
    FREE_PACKS = 'FREE_PACKS',
}

export enum ITEMS {
    VIPPASS = 'VIPPASS',
    // VIPPASSPLUS = 'VIPPASSPLUS',
}

export interface AwakeningAction {
    name?: string;
    action?: (() => void) | (() => Promise<void>);
    needFetch?: boolean;
}

export enum ClientEvents {
    TEST = 'TEST',

    TWITTER_FOLLOW = 'TWITTER_FOLLOW',
    TWITTER_REPOST = 'TWITTER_REPOST',
    TELEGRAM_JOIN = 'TELEGRAM_JOIN',

    CONNECT_TWITTER = 'CONNECT_TWITTER',
    CONNECT_WALLET = 'CONNECT_WALLET',
    CONNECT_EMAIL = 'CONNECT_EMAIL',

    CHECK_IN = 'CHECK_IN',
    BATTLE_PASS_MISSION = 'BATTLE_PASS_MISSION',
    CLAIM = 'CLAIM',
    FREE_PACK = 'FREE_PACK',

    TUTORIAL_FINISH = 'TUTORIAL_FINISH',
}

export interface BoostCategoryConfigText {
    shortDescription: Text;
    rulesText?: Text;
    actionText?: Text;

    context?: Record<string, unknown>;
}

export interface BoostCategoryConfig {
    levels: { name?: string; icon: string } | { name?: string; icon: string }[];

    name: string;
    itemName: string;
    counterName?: string;
    itemClassAlias?: string;
    ordered: boolean;
    order: number;

    getText(current?: BoostLevel, next?: BoostLevel, progress?: number): BoostCategoryConfigText;
    getRequired?(data: { mission?: components['schemas']['MissionDto']; achievement?: components['schemas']['AchievementDto'] }): number | undefined;
}

export enum BoostCategoryType {
    RANK = 'RANK',
    DAILY = 'DAILY',
    INVITES = 'INVITES',
    AWAKENING = 'AWAKENING',
}

export function getTagDataSplit(tags: string[], prefix: string): (string | undefined)[] {
    return (tags.find(tag => tag.startsWith(prefix))?.replace(prefix, '') ?? '').split('_');
}

export function getTagData(tags: string[], prefix: string): (string | undefined) {
    return (tags.find(tag => tag.startsWith(prefix))?.replace(prefix, '') ?? '');
}

export function getBattlePassPhaseConfig(phaseIndex: number): { title: string; phaseName: string; phaseShortName: string } | undefined {
    switch (phaseIndex) {
        case 0: return {
            title: 'THE LEGEND BEGINS',
            phaseName: 'OPEN BETA CHAPTER ONE: THE WORLD',
            phaseShortName: 'CH.01: THE WORLD',
        };

        case 1: return {
            title: 'THE CITY',
            phaseName: 'OPEN BETA CHAPTER TWO: THE CITY',
            phaseShortName: 'CH.02: THE CITY',
        };

        case 2: return {
            title: 'THE DARKNET',
            phaseName: 'OPEN BETA CHAPTER THREE: THE DARKNET',
            phaseShortName: 'CH.03: THE DARKNET',
        };
    }
}

export function getAwakeningAction(mission: components['schemas']['MissionDto']): AwakeningAction | undefined {
    switch (mission.completionCondition.sourceType) {
        case 'GAME_EVENT':
        case 'COUNTER': {
            return {
                name: 'Go in game',
                action: () => { /* TODO: use game link */ },
            };
        }

        case 'CLIENT_EVENT': {
            const completionCondition = mission.completionCondition as components['schemas']['InstantMissionConditionDto'];

            if (completionCondition.code.startsWith(ClientEvents.TEST)) {
                return {
                    name: 'Test',
                    action: async () => {
                        await achievementsService.postEvent({ code: completionCondition.code, payload: completionCondition.payload });
                    },
                    needFetch: true,
                };
            }

            switch (completionCondition.code) {
                case ClientEvents.TWITTER_FOLLOW: {
                    return {
                        action: async () => {
                            const payload = completionCondition.payload as Record<string, string> | undefined;

                            if (!payload || !payload.channelId) {
                                console.error('No payload for twitter follow event');
                                return;
                            }

                            const channelId = payload.channelId;

                            handleTwitterEvent(channelId);

                            await achievementsService.postEvent({
                                code: ClientEvents.TWITTER_FOLLOW,
                                payload: { channelId },
                            });
                        },
                        needFetch: true,
                    };
                }

                case ClientEvents.TWITTER_REPOST: {
                    return {
                        action: async () => {
                            const payload = completionCondition.payload as Record<string, string> | undefined;

                            if (!payload || !payload.channelId || !payload.tweetId) {
                                console.error('No payload for twitter repost event');
                                return;
                            }

                            const channelId = payload.channelId;
                            const tweetId = payload.tweetId;

                            handleTwitterEvent(`${channelId}/status/${tweetId}`);

                            await achievementsService.postEvent({
                                code: ClientEvents.TWITTER_REPOST,
                                payload: { channelId, tweetId },
                            });
                        },
                        needFetch: true,
                    };
                }

                case ClientEvents.TELEGRAM_JOIN: {
                    return {
                        action: async () => {
                            const payload = completionCondition.payload as Record<string, string> | undefined;

                            if (!payload || !payload.channelId) {
                                console.error('No payload for telegram join event');
                                return;
                            }

                            const channelId = payload.channelId;

                            handleTelegramEvent(channelId);

                            await achievementsService.postEvent({
                                code: ClientEvents.TELEGRAM_JOIN,
                                payload: { channelId },
                            });
                        },
                        needFetch: true,
                    };
                }

                case ClientEvents.CONNECT_TWITTER: {
                    return {
                        action: async () => {
                            handleConnectEvent();
                        },
                    };
                }

                case ClientEvents.CONNECT_WALLET: {
                    return {
                        action: async () => {
                            handleConnectEvent();
                        },
                    };
                }

                case ClientEvents.CONNECT_EMAIL: {
                    return {
                        action: async () => {
                            handleConnectEvent();
                        },
                    };
                }
            }
        }
    }
}

const handleTwitterEvent = async (targetUrl: string) => {
    navigateTo(`https://x.com/${targetUrl}`, {
        open: {
            target: '_blank',
            windowFeatures: {
                noopener: true,
                noreferrer: true,
            },
        },
    });
};

const handleTelegramEvent = async (targetUrl: string) => {
    navigateTo(`https://t.me/${targetUrl}`, {
        open: {
            target: '_blank',
            windowFeatures: {
                noopener: true,
                noreferrer: true,
            },
        },
    });
};

const handleConnectEvent = () => {
    navigateTo('/account');
};

export const calculateReward = (reward: Reward, assignedReward: Reward | undefined, pointsMultiplier: number): Reward => {
    if (assignedReward) {
        if (assignedReward.items.length === 0 && reward.items.length > 0) {
            return {
                ...assignedReward,
                items: reward.items,
            };
        }

        return assignedReward;
    }

    return {
        points: reward.points ? floor(reward.points * pointsMultiplier) : undefined,
        badges: reward.badges,
        pointsMultiplier: reward.pointsMultiplier,
        items: reward.items,
    };
};
