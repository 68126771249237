import revive_payload_client_4sVQNw7RlN from "/home/runner/work/77bit-site-v6/77bit-site-v6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/77bit-site-v6/77bit-site-v6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/77bit-site-v6/77bit-site-v6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/77bit-site-v6/77bit-site-v6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/77bit-site-v6/77bit-site-v6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/77bit-site-v6/77bit-site-v6/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/77bit-site-v6/77bit-site-v6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/home/runner/work/77bit-site-v6/77bit-site-v6/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/77bit-site-v6/77bit-site-v6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_AUP22rrBAc from "/home/runner/work/77bit-site-v6/77bit-site-v6/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  chunk_reload_client_UciE0i6zes,
  plugin_AUP22rrBAc
]