import type { components } from '~/schemas/achievements';
import type { components as inventoryComponents } from '~/schemas/inventory';
import type { BoostCategory, BoostLevel } from '~/stores/achievements/types/boost';
import { getBoostCategoryConfig } from '~/utils/achievements/boost';

export function convertBoostCustom(
    achievements: components['schemas']['UserAchievementStateDto'][],
    counters: Record<string, number>,
    items: inventoryComponents['schemas']['InventoryEntry'][],
): BoostCategory[] {
    const boostCategories = new Map<
        BoostCategoryType,
        {
            achievement: components['schemas']['UserAchievementStateDto'],
            index: number
        }[]
    >();

    for (const achievement of achievements) {
        const data = achievement.achievement;

        const [_categoryName, _index] = getTagDataSplit(data.tags, `${ACHIEVEMENT_GROUPS.BOOST}_LEVEL_`);
        if (!_categoryName || !_index || isNaN(parseInt(_index))) {
            console.error('No category type or index found for achievement', data.id);
            continue;
        }

        const categoryName = _categoryName as BoostCategoryType;

        if (!boostCategories.has(categoryName)) {
            boostCategories.set(categoryName, []);
        }

        boostCategories.get(categoryName)?.push({ achievement, index: parseInt(_index) });
    }

    const result: BoostCategory[] = [];

    for (const [categoryType, achievements] of boostCategories) {
        const config = getBoostCategoryConfig(categoryType);
        if (!config) {
            // console.warn('No config found for category type', categoryType);
            continue;
        }

        if (Array.isArray(config.levels) && achievements.length > config.levels.length) {
            console.warn('Achievements count does not match levels count for category type', categoryType);
            continue;
        }

        achievements.sort((a, b) => a.index - b.index);

        let type: BOOST_TYPE | undefined = undefined;

        const levels: BoostLevel[] = [];
        for (let i = 0; i < achievements.length; ++i) {
            const { achievement } = achievements[i];
            const levelConfig = Array.isArray(config.levels) ? config.levels[i] : config.levels;

            const tagType = getTagData(achievement.achievement.tags, `${ACHIEVEMENT_GROUPS.BOOST}_TYPE_`);
            if (!tagType) {
                console.error('No type data found for achievement', achievement.achievement.id);
                continue;
            }

            if (!Object.values(BOOST_TYPE).includes(tagType as BOOST_TYPE)) {
                console.error('Invalid type data found for achievement', achievement.achievement.id);
                continue;
            }

            if (!type) {
                type = tagType as BOOST_TYPE;
            } else if (type !== tagType) {
                console.error('Different types found for category', categoryType);
                type = undefined;
                break;
            }

            const counterCondition = achievement.achievement.grantCondition as
                components['schemas']['HasCounterValueGrantConditionDto'] | undefined;
            const inventoryCondition = achievement.achievement.grantCondition as
                components['schemas']['HasInventoryItemsGrantConditionDto'] | undefined;

            const required = counterCondition?.min ?? inventoryCondition?.items?.[0]?.amount;
            const mult = achievement.achievement.rewards?.[0].reward.pointsMultiplier?.value ?? 0;

            levels.push({
                name: levelConfig.name,
                required,
                mult,
                icon: levelConfig.icon,

                isReached: achievement.granted,
            });
        }

        if (!type) {
            console.warn('No type found for category', categoryType);
            continue;
        }

        const currentId = levels.findLastIndex(level => level.isReached);
        const current = levels[currentId] ?? undefined;
        const next = levels[currentId + 1] ?? undefined;
        const progress = (config.counterName ? counters[config.counterName] : (
            items.reduce(
                (sum, item) => sum + (item.itemClass.alias === config.itemClassAlias ? item.quantity : 0), 0,
            ) || undefined
        ));

        result.push({
            levels,
            progress,

            order: config.order,
            ordered: config.ordered,
            name: config.name,
            itemName: config.itemName,

            text: config.getText(current, next, progress),
        });
    }

    return result;
}

export function convertBoostDefault(
    multipliers: components['schemas']['PersonalPointsMultiplierComponentDto'][],
): BoostCategory[] {
    const boostMultipliers = multipliers.filter(multiplier => multiplier.name.startsWith('BOOST_'));

    const result: BoostCategory[] = [];

    const currentMap = new Map<string, number>();
    for (const multiplier of boostMultipliers) {
        currentMap.set(multiplier.name, multiplier.value);
    }

    const categoryMap = new Map<
        BoostCategoryType,
        {
            config: BoostCategoryConfig;
            order: number;
            data: {
                order: number;
                mult: number;
                value?: number;
                isReached: boolean;
            }[]
        }
    >();

    for (const name in achievements.multiplierComponents) {
        const multiplier = achievements.multiplierComponents[name];

        const [_categoryName, _order] = name.split('_').filter(p => p !== 'BOOST');
        if (!_categoryName || !_order || isNaN(parseInt(_order))) {
            console.error('No category name or level found for multiplier', name);
            continue;
        }

        const categoryName = _categoryName as BoostCategoryType;
        const order = parseInt(_order);

        const config = getBoostCategoryConfig(categoryName);
        if (!config) {
            // console.warn('No config found for category type', categoryName);
            continue;
        }

        if (!categoryMap.has(categoryName)) {
            categoryMap.set(categoryName, { config, data: [], order: config.order });
        }

        categoryMap.get(categoryName)?.data.push(
            {
                order,
                mult: multiplier.mult,
                value: multiplier.value,
                isReached: currentMap.has(name),
            },
        );
    }

    for (const [categoryName, { config, data, order }] of categoryMap) {
        const levels: BoostLevel[] = [];

        if (Array.isArray(config.levels) && data.length > config.levels.length) {
            console.warn('Level exceeds levels count for category type', categoryName);
            continue;
        }

        data.sort((a, b) => a.order - b.order);

        data.forEach(({ mult, value, isReached }, level) => {
            const levelConfig = Array.isArray(config.levels) ? config.levels[level] : config.levels;

            levels.push({
                name: levelConfig.name,
                mult,
                icon: levelConfig.icon,

                required: value ?? (level + 1),

                isReached,
            });
        });

        result.push({
            levels,

            order,
            ordered: config.ordered,
            name: config.name,
            itemName: config.itemName,

            text: config.getText(),
        });
    }

    return result.sort((a, b) => a.order - b.order);
}
