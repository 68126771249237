import mitt from 'mitt';

type AuthEvents = {
  inited: void;
  login: void;
  logout: void;
  bind: void;

  connectWallet: void;
  wrongNetwork: void;
}

type ArtUpgradeEvents = {
  /**
   * Array of art upgrade ragnatok token ids
   */
  upgrade: string[];
  upgradeComplete: {
    sevenSevenBitTokenIds: string[];
    ragnarokTokenIds: string[];
  };
  upgradeStatus: string;
}

type AchievementsEvents = {
    inited: void;
};

type PremiumStoreEvents = {
    purchase: void;
    showIframe: { iframe: HTMLIFrameElement; close: () => void };
    closeIframe: void;
};

type InventoryEvents = {
    init: void;
};

type VideoAnimationEvents = {
    changeState: [string, string, boolean?];
    addToQueue: [string, string];
    changeBlockState: boolean;
};

class EventService {
    readonly auth = mitt<AuthEvents>();
    readonly artUpgrade = mitt<ArtUpgradeEvents>();
    readonly achievements = mitt<AchievementsEvents>();
    readonly premiumStore = mitt<PremiumStoreEvents>();
    readonly inventory = mitt<InventoryEvents>();
    readonly videoAnimation = mitt<VideoAnimationEvents>();
}

const eventService = new EventService();

export default eventService;
