import createClient, { type Middleware } from 'openapi-fetch';
import type { paths } from '~/schemas/achievements';
import { authService } from '~/services/authService';
import eventService from '~/services/eventService';

export class AchievementsService {
    private _client?: ReturnType<typeof createClient<paths>>;
    private cloudflareAccountHash?: string;

    private _isInited = false;

    private get client() {
        if (!this._client) {
            throw new Error('Client is not initialized');
        }

        return this._client;
    }

    get isInited() {
        return this._isInited;
    }

    async init(host: string, cloudflareAccountHash?: string) {
        const authMiddleware: Middleware = {
            async onRequest({ request }) {
                const accessToken = authService.accessToken;
                if (!accessToken) {
                    throw new Error('Access token is not defined');
                }

                request.headers.set('Authorization', `Bearer ${accessToken}`);

                return request;
            },
        };

        const baseUrl = this.buildUrl(host, true);

        this._client = createClient<paths>({ baseUrl });
        this._client.use(authMiddleware);

        this.cloudflareAccountHash = cloudflareAccountHash;

        this._isInited = true;
        eventService.achievements.emit('inited');
    }

    getMissions = async({ anyOfTags, activeOnly }: { anyOfTags?: string[]; activeOnly?: boolean } = {}) =>
        (await this.client?.GET('/missions', { params: { query: { anyOfTags, activeOnly } } }))?.data;

    getAchievements = async({ anyOfTags, activeOnly }: { anyOfTags?: string[]; activeOnly?: boolean } = {}) =>
        (await this.client?.GET('/achievements', { params: { query: { anyOfTags, activeOnly } } }))?.data;

    getDailyMissions = async({ anyOfTags, activeOnly }: { anyOfTags?: string[]; activeOnly?: boolean  } = {}) =>
        (await this.client?.GET('/missions/daily', { params: { query: { anyOfTags, activeOnly } } }))?.data;

    getAvailableRewards = async() =>
        (await this.client?.GET('/rewards/available'))?.data;

    claimReward = async({ rewardId }: { rewardId: string }) =>
        (await this.client?.POST('/rewards/{id}/claim', { params: { path: { id: rewardId } } }))?.data;

    claimAllRewards = async() =>
        (await this.client?.POST('/rewards/claim-all'))?.data;

    getGrantedRewards = async() =>
        (await this.client?.GET('/rewards/granted'))?.data;

    getReferralMultipliers = async() =>
        (await this.client?.GET('/rewards/referral-multipliers'))?.data;

    getScore = async() =>
        (await this.client?.GET('/users/me/score'))?.data;

    getBadges = async() =>
        (await this.client?.GET('/users/me/badges'))?.data;

    getCounter = async({ name }: { name: string }) =>
        (await this.client?.GET('/users/me/counters/{name}', { params: { path: { name } } }))?.data;

    getItems = async() =>
        (await this.client?.GET('/users/me/items'))?.data;

    getReferralTree = async({ maxReferrerLevel, maxReferralLevel }: { maxReferrerLevel: number, maxReferralLevel: number }) =>
        (await this.client?.GET('/users/me/referral-tree', { params: { query: { maxReferrerLevel, maxReferralLevel } } }))?.data;

    getPointsMultiplier = async() =>
        (await this.client?.GET('/users/me/points-multiplier'))?.data;

    getPointsMultiplierComponents = async() =>
        (await this.client?.GET('/users/me/points-multiplier/components'))?.data;

    getLeaderboard = async({ offset, limit }: { offset: number, limit: number }) =>
        (await this.client?.GET('/users/leaderboard', { params: { query: { offset, limit } } }))?.data;

    postEvent = async({ code, payload }: { code: string, payload?: Record<string, unknown> }) =>
        (await this.client?.POST('/events', { body: { code, payload: payload as Record<string, never> } }))?.data;

    getMiningSettings = async() =>
        (await this.client?.GET('/mining/settings'))?.data;

    getActiveMining = async() =>
        (await this.client?.GET('/mining/latest'))?.data;

    getFinishedMinings = async() =>
        (await this.client?.GET('/mining/finished'))?.data;

    startMining = async() =>
        (await this.client?.POST('/mining/start'))?.data;

    finishMining = async() =>
        (await this.client?.POST('/mining/finish'))?.data;

    fetchAutomationMiningEnabled = async() =>
        (await this.client?.GET('/mining/automation-enabled'))?.data;

    getItemImageUrl = (itemId: string, width: number = 200, height: number = 200) =>
        this.cloudflareAccountHash ?
            `https://imagedelivery.net/${this.cloudflareAccountHash}/items/v1/${itemId}/w=${width},h=${height}`
            : undefined;

    private buildUrl(host: string, useSSL: boolean = true) {
        return `${useSSL ? 'https' : 'http'}://${host}/api`;
    }
}

export const achievementsService = new AchievementsService();
