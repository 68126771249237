import _achievements from '~/vendor/endy-export/achievements.json';
import _premiumStore from '~/vendor/endy-export/premiumStore.json';
import _bitBotPremiumStore from '~/vendor/endy-export/bitBotPremiumStore.json';
import _hardCurrencyPremiumStore from '~/vendor/endy-export/hardCurrencyPremiumStore.json';

export interface PointsMultiplierReward {
    value: number;
    expiresInSec?: number;
    name: string;
}

export interface Item {
    itemClassAlias: string;
    amount: number;
}

export interface Reward {
    points?: number;
    badges?: number;
    pointsMultiplier?: PointsMultiplierReward;
    items?: Item[];
}

interface InstantMissionCondition {
    sourceType: 'CLIENT_EVENT' | 'GAME_EVENT';
    code: string;
    payload?: Record<string, string>;
}

interface CounterCondition {
    sourceType: 'COUNTER';
    counter: string;
    target: number;
}

export type CompletionCondition = InstantMissionCondition | CounterCondition;

export interface AwakeningMission {
    name: string;
    description: string;
    reward: Reward;
    completionCondition: CompletionCondition;
    type: 'DAY' | 'LIMITED' | 'INFINITE';
    locked: boolean;
}

export interface CounterBoostLevel {
    required: number;
    multiplier: number;
}

export interface ItemsBoostLevel {
    items: Item[];
    multiplier: number;
}

export type BoostCategory = {
    name: string;
    itemClassAlias?: string;
    counter?: string;
    levels: {
        required: number;
        multiplier: number;
    }[];
};

export interface BattlePassMission {
    name: string;
    description: string;

    reward: Reward;
    premiumReward: Reward;

    completionCondition: CompletionCondition;
}

export interface BattlePassPhase {
    missions: BattlePassMission[];
}

export interface GrantCondition {
    conditionType: 'HAS_INVENTORY_ITEMS' | 'HAS_COUNTER_VALUE';
    items?: Item[];
    name?: string;
    min?: number;
    max?: number;
}

// Needs to deep customization of specific achievements is required for some edge cases.
// Therefore, support for this structure has been made
export interface AdditionalAchievements {
    name: string;
    description: string;
    alias?: string;
    achievementTag: string;
    grantCondition: GrantCondition;
    rewards: {
        applied: 'ONCE' | 'EVERY_TIME_ACHIEVEMENT_GRANTED';
        duration: 'PERMANENT' | 'WHILE_ACHIEVEMENT_GRANTED';
        reward: Reward;
    }[];
    activeFrom?: string;
}

export interface Invites {
    activeFrom?: string;
    inviteNumber: number;
    reward: Reward;
}

export interface MultiplierComponent {
    mult: number;
    value?: number;
    order: number;
}

export interface FreePack {
    item: Item;
    name: string;
    description: string;
}

export interface Achievements {
    additionalAchievements: AdditionalAchievements[];
    dailyRewards: Reward[];
    awakeningMissions: AwakeningMission[];
    boostCategories: BoostCategory[];
    battlePass: {
        phases: BattlePassPhase[];
    };
    referralMult: number[];
    invites: Invites[];
    multiplierComponents: Record<string, MultiplierComponent>;
    freePacks: Record<string, FreePack>;
}

export const achievements = _achievements as Achievements;

export enum ItemRarity {
    common = 'common',
    uncommon = 'uncommon',
    rare = 'rare',
    mythic = 'mythic',
    legendary = 'legendary',
}

export enum ItemType {
    equipment = 'equipment',
    resource = 'resource',
    consumable = 'consumable',
    note = 'note',
    special = 'special',
    currency = 'currency',
    gacha = 'gacha',
}

export interface ItemDesc {
    id: string;
    name: string;
    description?: string;
    type: ItemType;
    typeDisplay?: string;
    equipmentContainer?: string;
    consumableSubtype?: string;
    rarity?: ItemRarity;
    NFT?: boolean;
    oldPriceAmount?: number;
    bonusQuantity?: number;
    dailySaleDate?: string;
    flashSaleDate?: string;
}

export interface Category {
    name: string;
    items: ItemDesc[];
}

export interface PremiumStore {
    categories: Category[];
    endSaleDate?: string;
}

export const premiumStore = _premiumStore as PremiumStore;

export const bitBotPremiumStore = _bitBotPremiumStore as PremiumStore;

export const hardCurrencyPremiumStore = _hardCurrencyPremiumStore as PremiumStore;

export enum CURRENCIES {
    PIXEL = '6610a55c6296612f',
    BITS = 'cbb459566d03b58d',
    PRIMAL_CODE = '5c12eb32d0f1d86a',
    ENCRYPTED_DATA = 'adf46bfbbe3bfee4',
    DARKNET_PERMANENT = '869b413bd595b2a9',
    DARKNET_TEMP = '1222f851b94f377e',
}

export const randomUserNames = [
    'user1',
    'user2',
    'user3',
    'user4',
    'user5',
    'user6',
    'user7',
    'user8',
    'user9',
    'user10',
];

export const LOGIN_RESEND_TIMEOUT_S = 32;

export const MAX_GIFS_COUNT = 7;

export const TWEET_TEXTS = {
    1: 'Ready Player One?',
    2: 'One of us! One of us!',
    3: 'Beta squad, assemble!',
    4: 'Get in, Noob',
    5: 'Ready to join the game?',
    6: 'Noobs welcome, here\'s the key',
    7: 'Enter the game, Noob',
};
