import { achievementsService } from '~/services/achievementsService';
import { achievementsAdminService } from '~/services/achievementsAdminService';
import eventService from '~/services/eventService';
import { ENV_DEV } from '~/utils/env';

import { RemovableRef, useStorage } from '@vueuse/core';
import dayjs, { Dayjs } from 'dayjs';

import type { DailyReward } from '~/stores/achievements/types/daily';
import { convertDailyRewards } from '~/stores/achievements/dailyRewards';

import type { BoostCategory } from '~/stores/achievements/types/boost';
import { convertBoostCustom, convertBoostDefault } from '~/stores/achievements/boost';

import type { AwakeningMission } from '~/stores/achievements/types/awakeningMissions';
import { convertAwakeningMissions } from '~/stores/achievements/awakeningMissions';

import type { Phase } from '~/stores/achievements/types/battlePass';
import { convertBattlePass } from '~/stores/achievements/battlePass';

import type { Reward, Status } from '~/stores/achievements/types/common';
import { convertItemAchievements } from '~/stores/achievements/itemAchievements';

import { Referral } from '~/stores/achievements/types/referrals';
import { convertReferrals } from '~/stores/achievements/referrals';

import { convertAssignedRewards } from '~/stores/achievements/assignedRewards';

import type { components } from '~/schemas/achievements';
import { authService } from '~/services/authService';
import { useTutorialStore } from '~/stores/tutorial';

export interface DataEntry {
    isFetching: boolean;
    lastFetchedAt: number | null; // timestamp
}

export interface DailyLoginData extends DataEntry {
    rewards: DailyReward[];
}

export interface AwakeningData extends DataEntry {
    missions: AwakeningMission[];
    lockedMissionsCount: number;
}

export interface BoostData extends DataEntry {
    categories: BoostCategory[];
}

export interface BattlePassData extends DataEntry {
    phases: Phase[];
}

export interface ItemAchievementsData extends DataEntry {
    items: ITEMS[];
}

export interface RewardsData extends DataEntry {
    missionStatusMap: Record<string, Status>;
    missionStatusesMap: Record<string, Record<string, Status>>;
    missionRewardIds: Record<string, string[]>;
    missionRewards: Record<string, Reward>;
}

export interface ReferralsData extends DataEntry {
    referrals: Referral[];
}

export interface UserData extends DataEntry {
    pointsMultiplier: number;
    points: number;
}

export interface FreePacksData extends DataEntry {
    packs: Record<string, components['schemas']['UserMissionProgressDto']>;
}

export interface MiningData {
    currentMining?: components['schemas']['MiningDto'];
    miningSettings?: components['schemas']['GlobalMiningSettingsDto'];
    isHaveBitBot?: boolean;
    localStartDate?: Dayjs;
    localEndDate?: Dayjs;
    notClaimedMinings?: string[],
    isRequestingCurrentMining?: boolean,
    isRequestingToBuyBitBot: boolean,
}

export const useAchievementsStore = defineStore('achievements', () => {
    let xApiKey: RemovableRef<string | undefined>;
    if (ENV_DEV) {
        xApiKey = useStorage<string | undefined>('X-API-KEY', undefined);
    }

    const { achievementsHost, cloudflareAccountHash } = storeToRefs(useRemoteConfigStore());
    const tutorialStore = useTutorialStore();
    const inventoryStore = useInventoryStore();

    const { isLoggedIn } = storeToRefs(useAuthStore());

    watch(isLoggedIn, async isLoggedIn => {
        if (isLoggedIn) {
            if (!achievementsHost.value) {
                throw new Error('Achievements host is not defined');
            }

            achievementsService.init(achievementsHost.value, cloudflareAccountHash.value);

            if (ENV_DEV) {
                if (xApiKey?.value) {
                    achievementsAdminService.init(achievementsHost.value, xApiKey.value);
                }
            }

            console.log('Post login achievements init');
            switch (authService.authSdkProvider?.loginType?.type) {
                case 'ETH_ADDRESS':
                    await achievementsService.postEvent({ code: ClientEvents.CONNECT_WALLET });
                    break;

                case 'TWITTER':
                    await achievementsService.postEvent({ code: ClientEvents.CONNECT_TWITTER });
                    break;

                case 'EMAIL':
                    await achievementsService.postEvent({ code: ClientEvents.CONNECT_EMAIL });
                    break;
            }
        }
    });

    const isInited = ref(false);
    const isLoading = ref(false);
    const isHaveAirDropChance = ref(false);

    eventService.achievements.on('inited', async () => {
        isInited.value = achievementsService.isInited;
    });

    const user = reactive<UserData>({ pointsMultiplier: 1, points: 0, isFetching: false, lastFetchedAt: null });
    const dailyLogin = reactive<DailyLoginData>({ rewards: [], isFetching: false, lastFetchedAt: null });
    const awakening = reactive<AwakeningData>({ missions: [], lockedMissionsCount: 0, isFetching: false, lastFetchedAt: null });
    const boost = reactive<BoostData>({ categories: [], isFetching: false, lastFetchedAt: null });
    const battlePass = reactive<BattlePassData>({ phases: [], isFetching: false, lastFetchedAt: null });
    const itemAchievements = reactive<ItemAchievementsData>({ items: [], isFetching: false, lastFetchedAt: null });
    const rewards = reactive<RewardsData>({
        missionStatusMap: {},
        missionStatusesMap: {},
        missionRewardIds: {},
        missionRewards: {},
        isFetching: false,
        lastFetchedAt: null,
    });
    const referrals = reactive<ReferralsData>({ referrals: [], isFetching: false, lastFetchedAt: null });
    const freePacks = reactive<FreePacksData>({ packs: {}, isFetching: false, lastFetchedAt: null });
    const mining = reactive<MiningData>({ isRequestingToBuyBitBot: false });

    // [points, pointsMultiplier]
    const fetchUser = async ({ delay = 0 }: { delay?: number } = {}) => {
        if (user.isFetching) {
            console.debug('already fetching user, skipping');
            return;
        }

        if (user.lastFetchedAt !== null && Date.now() - user.lastFetchedAt < delay) {
            console.debug('fetching user too often, skipping');
            return;
        }

        console.debug('fetching user');
        user.isFetching = true;

        const pointsMultiplier = await achievementsService.getPointsMultiplier();

        user.pointsMultiplier = pointsMultiplier ?? 1;

        await fetchPoints();

        user.isFetching = false;
        user.lastFetchedAt = Date.now();
    };

    const fetchDailyLogin = async ({ delay = 0 }: { delay?: number } = {}) => {
        if (dailyLogin.isFetching) {
            console.debug('already fetching daily login, skipping');
            return;
        }

        if (dailyLogin.lastFetchedAt !== null && Date.now() - dailyLogin.lastFetchedAt < delay) {
            console.debug('fetching daily login too often, skipping');
            return;
        }

        console.debug('fetching daily login');
        dailyLogin.isFetching = true;

        const missions = await achievementsService.getMissions({
            anyOfTags: [ACHIEVEMENT_GROUPS.DAILY_LOGIN],
            activeOnly: false,
        }) ?? [];

        dailyLogin.rewards = convertDailyRewards(missions);

        dailyLogin.isFetching = false;
        dailyLogin.lastFetchedAt = Date.now();
    };

    const fetchAwakening = async ({ delay = 0 }: { delay?: number } = {}) => {
        if (awakening.isFetching) {
            console.debug('already fetching awakening, skipping');
            return;
        }

        if (awakening.lastFetchedAt !== null && Date.now() - awakening.lastFetchedAt < delay) {
            console.debug('fetching awakening too often, skipping');
            return;
        }

        console.debug('fetching awakening');
        awakening.isFetching = true;

        const [
            missions = [],
            dailyMissions = [],
        ] = await Promise.all([
            achievementsService.getMissions({ anyOfTags: [ACHIEVEMENT_GROUPS.AWAKENING], activeOnly: false }),
            achievementsService.getDailyMissions({ anyOfTags: [ACHIEVEMENT_GROUPS.AWAKENING], activeOnly: false }),
        ]);

        awakening.missions = convertAwakeningMissions(missions, dailyMissions);
        awakening.lockedMissionsCount = achievements.awakeningMissions.filter(mission => mission.locked).length;

        awakening.isFetching = false;
        awakening.lastFetchedAt = Date.now();
    };

    const fetchBoost = async ({ delay = 0 }: { delay?: number } = {}) => {
        if (boost.isFetching) {
            console.debug('already fetching boost, skipping');
            return;
        }

        if (boost.lastFetchedAt !== null && Date.now() - boost.lastFetchedAt < delay) {
            console.debug('fetching boost too often, skipping');
            return;
        }

        console.debug('fetching boost');
        boost.isFetching = true;

        await inventoryStore.fetchInventory();

        const items = inventoryStore.inventory.items;
        const achievements = await achievementsService.getAchievements({ anyOfTags: [ACHIEVEMENT_GROUPS.BOOST] }) ?? [];
        const multiplierComponents = (await achievementsService.getPointsMultiplierComponents())?.components ?? [];

        const counters: Record<string, number> = {};
        for (const counter of Object.values(COUNTER)) {
            const value = await achievementsService.getCounter({ name: counter });
            if (value !== undefined) {
                counters[counter] = value;
            }
        }

        boost.categories = [
            ...convertBoostCustom(achievements, counters, items),
            ...convertBoostDefault(multiplierComponents),
        ];

        boost.isFetching = false;
        boost.lastFetchedAt = Date.now();
    };

    const fetchBattlePass = async ({ delay = 0 }: { delay?: number } = {}) => {
        if (battlePass.isFetching) {
            console.debug('already fetching battle pass, skipping');
            return;
        }

        if (battlePass.lastFetchedAt !== null && Date.now() - battlePass.lastFetchedAt < delay) {
            console.debug('fetching battle pass too often, skipping');
            return;
        }

        console.debug('fetching battle pass');
        battlePass.isFetching = true;

        const missions = await achievementsService.getMissions({ anyOfTags: [ACHIEVEMENT_GROUPS.BATTLE_PASS] }) ?? [];

        battlePass.phases = convertBattlePass(missions);

        battlePass.isFetching = false;
        battlePass.lastFetchedAt = Date.now();
    };

    const fetchRewards = async ({ delay = 0 }: { delay?: number } = {}) => {
        if (rewards.isFetching) {
            console.debug('already fetching rewards, skipping');
            return;
        }

        if (rewards.lastFetchedAt !== null && Date.now() - rewards.lastFetchedAt < delay) {
            console.debug('fetching rewards too often, skipping');
            return;
        }

        console.debug('fetching rewards');
        rewards.isFetching = true;

        const missionStatusesMap: Record<string, Record<string, Status>> = {};
        const missionRewardIds: Record<string, string[]> = {};

        const availableRewards = await achievementsService.getAvailableRewards() ?? [];
        const grantedRewards = await achievementsService.getGrantedRewards() ?? [];

        const allRewards = [...availableRewards, ...grantedRewards];

        for (const reward of allRewards) {
            if (!reward.missionId) {
                continue;
            }

            missionStatusesMap[reward.missionId] ??= {};
            missionStatusesMap[reward.missionId][reward.id] = reward.status;
        }

        const missionStatusMap: Record<string, Status> = Object.fromEntries(
            allRewards.filter(reward => reward.missionId)
                .map(reward => [reward.missionId, reward.status]),
        );

        const missionRewardsRaw: Record<string, components['schemas']['AssignedRewardDto'][]> = {};

        const missionRewards: Record<string, Reward> = {};

        for (const reward of allRewards) {
            const { missionId, id } = reward;

            if (missionId) {
                missionRewardsRaw[missionId] ??= [];
                missionRewardsRaw[missionId].push(reward);

                missionRewardIds[missionId] ??= [];
                missionRewardIds[missionId].push(id);
            }
        }

        for (const [missionId, rewardsData] of Object.entries(missionRewardsRaw)) {
            missionRewards[missionId] = convertAssignedRewards(rewardsData);
        }

        rewards.missionStatusMap = missionStatusMap;
        rewards.missionStatusesMap = missionStatusesMap;
        rewards.missionRewardIds = missionRewardIds;
        rewards.missionRewards = missionRewards;

        rewards.isFetching = false;
        rewards.lastFetchedAt = Date.now();
    };

    const fetchItemAchievements = async ({ delay = 0 }: { delay?: number } = {}) => {
        if (itemAchievements.isFetching) {
            console.debug('already fetching item achievements, skipping');
            return;
        }

        if (itemAchievements.lastFetchedAt !== null && Date.now() - itemAchievements.lastFetchedAt < delay) {
            console.debug('fetching item achievements too often, skipping');
            return;
        }

        console.debug('fetching item achievements');
        itemAchievements.isFetching = true;

        const achievements = await achievementsService.getAchievements({ anyOfTags: [ACHIEVEMENT_GROUPS.ITEM] }) ?? [];

        itemAchievements.items = convertItemAchievements(achievements);

        itemAchievements.isFetching = false;
        itemAchievements.lastFetchedAt = Date.now();
    };

    const fetchPoints = async () => {
        user.points = await achievementsService.getScore() ?? 0;
    };

    const fetchReferrals = async ({ delay = 0 }: { delay?: number } = {}) => {
        if (referrals.isFetching) {
            console.debug('already fetching referrals, skipping');
            return;
        }

        if (referrals.lastFetchedAt !== null && Date.now() - referrals.lastFetchedAt < delay) {
            console.debug('fetching referrals too often, skipping');
            return;
        }

        console.debug('fetching referrals');
        referrals.isFetching = true;

        const multipliers = (await achievementsService.getReferralMultipliers())?.multipliers ?? [];

        const maxLevel = Math.max(...multipliers.map(item => Math.abs(item.referralLevel)));

        const _referrals = await achievementsService.getReferralTree({
            maxReferralLevel: maxLevel,
            maxReferrerLevel: maxLevel,
        }) ?? [];

        referrals.referrals = convertReferrals(_referrals);

        referrals.isFetching = false;
        referrals.lastFetchedAt = Date.now();
    };

    const fetchFreePacks = async () => {
        if (freePacks.isFetching) {
            console.debug('already fetching free packs, skipping');
            return;
        }

        if (freePacks.lastFetchedAt !== null && Date.now() - freePacks.lastFetchedAt < 0) {
            console.debug('fetching free packs too often, skipping');
            return;
        }

        console.debug('fetching free packs');
        freePacks.isFetching = true;

        const missions = await achievementsService.getDailyMissions({ anyOfTags: [ACHIEVEMENT_GROUPS.FREE_PACKS] }) ?? [];

        freePacks.packs = Object.fromEntries(
            missions.map(mission => {
                const name = getTagData(mission.mission.tags, 'FREE_PACK_');

                if (!name) {
                    return;
                }

                return [name, mission];
            }).filter(isDefined),
        );

        freePacks.isFetching = false;
        freePacks.lastFetchedAt = Date.now();
    };

    // [rewards]
    const fetchCurrentMining = async () => {
        mining.isRequestingCurrentMining = true;
        const currentMining = await achievementsService.getActiveMining();

        if (!currentMining?.finishedAt) {
            assignCurrentMining(currentMining);
            mining.isRequestingCurrentMining = false;
            return;
        }

        const rewards = await achievementsService.getAvailableRewards() ?? [];

        mining.notClaimedMinings = rewards.filter(item => !!item.miningId).map((item) => item.id);

        assignCurrentMining(mining.notClaimedMinings.length ? currentMining : undefined);
        mining.isRequestingCurrentMining = false;
    };

    const fetchMiningSettings = async () => {
        mining.miningSettings = await achievementsService.getMiningSettings();
    };

    const fetchBitBotStatus = async () => {
        mining.isHaveBitBot = !!(await achievementsService.fetchAutomationMiningEnabled());
    };

    const claimMissionRewards = async (missionId: string) => {
        const rewardIds = rewards.missionRewardIds[missionId];
        if (!rewardIds || rewardIds.length === 0) {
            console.warn('No reward found for mission', missionId);
            return;
        }

        for (const rewardId of rewardIds) {
            await achievementsService.claimReward({ rewardId });
        }

        // TODO: do it synchronously so that it is faster and does not occupy the js loop
        fetchPoints();
    };

    const startMining = async () => {
        if (mining.isRequestingCurrentMining) {
            return;
        }

        mining.currentMining = await achievementsService.startMining();

        return assignCurrentMining(mining.currentMining);
    };

    const toggleBlockNewBitBotPurchasing = () => {
        mining.isRequestingToBuyBitBot = !mining.isRequestingToBuyBitBot;
    };

    const claimMiningRewards = async () => {
        for (const rewardId of mining.notClaimedMinings || []) {
            await achievementsService.claimReward({ rewardId });
        }

        mining.currentMining = undefined;

        fetchPoints();
    };

    const assignCurrentMining = (currentMining?: components['schemas']['MiningDto']) => {
        if (!currentMining || !mining.miningSettings) {
            return;
        }

        mining.currentMining = currentMining;

        const timePassedDate = dayjs(currentMining.earnedPointsUpdatedAt);
        const startedAt = dayjs(currentMining?.startedAt);
        const diff = timePassedDate.diff(startedAt, 'millisecond');

        const durationMs = (mining.miningSettings?.maxDurationMinutes || 0) * 60 * 1000;

        mining.localStartDate = dayjs().subtract(diff, 'millisecond');
        mining.localEndDate = mining.localStartDate.add(durationMs, 'millisecond');

        return mining.currentMining;
    };

    const handleShowTutorial = async () => {
        if (tutorialStore.tutorial.isTutorialLocalShowed) {
            return;
        }

        const tutorialMissions = await achievementsService.getMissions({ anyOfTags: [ACHIEVEMENT_GROUPS.TUTORIAL] }) ?? [];

        const finishMission = tutorialMissions.find(data =>
            (data.mission.completionCondition as components['schemas']['InstantMissionConditionDto'])
                .code === ClientEvents.TUTORIAL_FINISH);

        if (finishMission?.progress === 0) {
            tutorialStore.setIsLocalTutorialShowed(true);
            tutorialStore.toggleOpenTutorial(true);
        }
    };

    const sendFinishTutorialEvent = () => {
        achievementsService.postEvent({ code: ClientEvents.TUTORIAL_FINISH });
    };

    const sync = async () => {
        await Promise.allSettled([
            fetchUser(),
            fetchPoints(),
            achievementsService.postEvent({ code: ClientEvents.CHECK_IN }),
        ]);
    };

    const fetchAll = async () => {
        isLoading.value = true;

        await achievementsService.postEvent({ code: ClientEvents.CHECK_IN });

        await Promise.allSettled([
            fetchUser(),
            fetchRewards(),
            fetchItemAchievements(),
        ]);

        await Promise.allSettled([
            fetchMiningSettings(),
        ]);

        await fetchCurrentMining();

        await Promise.allSettled([
            fetchDailyLogin(),
            fetchAwakening(),
            fetchBoost(),
            fetchBattlePass(),
            fetchReferrals(),
            fetchFreePacks(),
        ]);

        isLoading.value = false;
    };

    return {
        isInited,
        isLoading,

        user,
        dailyLogin,
        awakening,
        boost,
        battlePass,
        rewards,
        itemAchievements,
        referrals,
        freePacks,
        isHaveAirDropChance,

        mining,

        fetchUser,
        fetchDailyLogin,
        fetchAwakening,
        fetchBoost,
        fetchBattlePass,
        fetchRewards,
        fetchItemAchievements,
        fetchReferrals,
        fetchBitBotStatus,
        fetchPoints,
        fetchFreePacks,
        fetchCurrentMining,

        startMining,
        claimMiningRewards,

        fetchAll,
        claimMissionRewards,
        sync,

        handleShowTutorial,
        sendFinishTutorialEvent,
        toggleBlockNewBitBotPurchasing,
    };
});
