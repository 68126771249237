export interface TutorialData {
    enabled: boolean;
    initialTutorialStep: number;
    enabledElements: string[];
    isTutorialLocalShowed: boolean;
}

export const useTutorialStore = defineStore('tutorial', () => {
    const tutorial = reactive<TutorialData>({
        enabled: false,
        initialTutorialStep: 0,
        isTutorialLocalShowed: false,
        enabledElements: [],
    });

    const toggleOpenTutorial = (value: boolean) => {
        tutorial.enabled = value;
    };

    const setInitialTutorialStep = (value: number) => {
        tutorial.initialTutorialStep = value;
    };

    const setTutorialEnabledElements = (elements: string[]) => {
        tutorial.enabledElements = elements;
    };

    const setIsLocalTutorialShowed = (value: boolean) => {
        tutorial.isTutorialLocalShowed = value;
    };

    return {
        tutorial,

        toggleOpenTutorial,
        setInitialTutorialStep,
        setTutorialEnabledElements,
        setIsLocalTutorialShowed,
    };
});
