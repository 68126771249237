import { MOBILE_WIDTH, TARGET_HEIGHT, TARGET_WIDTH } from '~/constants/scale';
import setPropertyValue from '~/utils/setPropertyValue';

export const useScaleStore = defineStore('scaleStore', () => {
    const viewportWidth = ref(0);
    const viewportHeight = ref(0);

    const scaleFunc = (width: number, height: number) => {
        return Math.min(width / TARGET_WIDTH, height / TARGET_HEIGHT);
    };

    const scale = computed(() => process.server ? 1 : scaleFunc(viewportWidth.value, viewportHeight.value));
    throttledWatch(
        () => scale.value,
        () => {
            setPropertyValue('--scale', scale.value);
        },
        {
            immediate: true,
            throttle: 100,
        },
    );

    const isMobile = computed(() => process.client && viewportWidth.value <= MOBILE_WIDTH);
    const isScaleCalculated = computed(() => scale.value !== 0);

    const toScaledPx = (num: number) => `${Math.round(num * scale.value)}px`;

    return {
        viewportWidth,
        viewportHeight,
        scale,
        isScaleCalculated,
        isMobile,
        toScaledPx,
    };
});
