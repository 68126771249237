import type { components } from '~/schemas/achievements';

export function convertItemAchievements(
    achievements: components['schemas']['UserAchievementStateDto'][],
): ITEMS[] {
    const itemSet: ITEMS[] = [];

    for (const achievement of achievements) {
        const data = achievement.achievement;

        const itemCode = getTagData(data.tags, `${ACHIEVEMENT_GROUPS.ITEM}_`);
        if (!itemCode) {
            console.error('No item code data found for achievement', data.id);
            continue;
        }

        if (Object.values(ITEMS).every(item => item !== itemCode)) {
            console.error('Unknown item code', itemCode);
            continue;
        }

        if (achievement.granted)
            itemSet.push(itemCode as ITEMS);
    }

    return itemSet;
}
