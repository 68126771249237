/* eslint-disable max-len */
import { components } from '~/schemas/achievements';

export function getBoostCategoryConfig(categoryType: BoostCategoryType): BoostCategoryConfig | undefined {
    const whiteColor = 'rgba(255, 255, 255, 0.77)';
    const blueColor = 'rgba(105, 255, 228, 0.77)';

    switch (categoryType) {
        case BoostCategoryType.DAILY: {
            return {
                levels: { icon: 'fire' },

                order: 1,
                ordered: true,
                name: 'Daily',
                itemName: 'Day',
                counterName: COUNTER.UNIQUE_LOGIN_DAYS,

                getText: () => {

                    return {
                        shortDescription: ['LOG IN EVERY DAY TO INCREASE', 'YOUR BYTES EARNING MULTIPLIER.'],
                        rulesText: [
                            `LOG IN EVERY DAY TO [f.color("INCREASE YOUR", "${whiteColor}")] [f.color("BYTES", "${blueColor}")] [f.color("EARNING", "${whiteColor}")]`,
                            'MULTIPLIER AND COLLECT AWESOME REWARDS! DON’T MISS A DAY',
                            'IF YOU DON’T WANT TO FALL BEHIND YOUR CREW.',
                        ],
                    };
                },

                getRequired: ({ mission }) => {
                    const completionCondition = mission?.completionCondition as components['schemas']['CounterConditionDto'];

                    return completionCondition.target;
                },
            };
        }

        case BoostCategoryType.RANK: {
            return {
                levels: [
                    // { name: 'E', icon: 'letters/E' },
                    { name: 'D', icon: 'letters/D' },
                    { name: 'C', icon: 'letters/C' },
                    { name: 'B', icon: 'letters/b' },
                    { name: 'A', icon: 'letters/A' },
                    { name: 'S', icon: 'letters/S' },
                ],

                order: 0,
                ordered: true,
                name: 'Rank',
                itemName: 'Nft',
                itemClassAlias: 'ronin_classic',

                getText: () => {
                    return {
                        shortDescription: ['YOUR “RANK” IS DETERMINED BY', 'THE NUMBER OF NFTS YOU HOLD.'],
                        rulesText: [
                            'YOUR “RANK” IS DETERMINED BY THE NUMBER OF',
                            '77-BIT NFTS YOU HOLD.',
                        ],
                    };
                },
            };
        }

        case BoostCategoryType.AWAKENING: {
            return {
                levels: { icon: 'sparkle' },

                order: 3,
                ordered: false,
                name: 'Missions',
                itemName: 'Mission',

                getText: () => {
                    return {
                        shortDescription: ['COMPLETE AWAKENING MISSIONS TO', 'INCREASE YOUR BOOST.'],
                        rulesText: [
                            `COMPLETE AWAKENING MISSIONS [f.color("TO INCREASE YOUR BOOST.", "${whiteColor}")]`,
                            'STAY UPDATED BY FOLLOWING OUR SOCIAL MEDIA, PARTICIPATING',
                            'IN EVENTS, AND ENGAGING WITH THE COMMUNITY.',
                        ],
                    };
                },
            };
        }

        case BoostCategoryType.INVITES: {
            return {
                levels: { icon: 'persons' },

                order: 2,
                ordered: true,
                name: 'Invites',
                itemName: 'Invite',

                getText: () => {
                    return {
                        shortDescription: ['INVITE FRIENDS TO YOUR CREW', 'AND GET AN EXTRA BOOST.'],
                        rulesText: [
                            `INVITE FRIENDS TO YOUR CREW AND [f.color("GET AN EXTRA BOOST", "${whiteColor}")] FOR`,
                            'EACH ONE. SHARE YOUR CODE OR REFERRAL LINK, AND EARN',
                            'PROFIT FROM FRIENDS WHO JOIN THE GAME.',
                        ],
                    };
                },

                getRequired: ({ achievement }) => {
                    const grantCondition = achievement?.grantCondition as components['schemas']['HasCounterValueGrantConditionDto'];

                    return grantCondition.min;
                },
            };
        }
    }
}
