interface Jwt {
    aud: string;
    exp: number;
    iat: number;
    iss: string;
    sub: string;
    uid: string;
}

export const parseJwt = (token: string): Jwt => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const json = decodeURIComponent(
        window.atob(base64)
            .split('')
            .map(char => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
            .join(''),
    );

    return JSON.parse(json);
};
