import { useStorage } from '@vueuse/core';
import type { RemoteEnvConfig } from '~/utils/remoteConfig/types';
import { ENV_DEV }from '~/utils/env';

export interface EnvConfig {
    authHost: string;
    authApiHost: string;
    achievementsHost: string;
    premiumStoreHost: string;
    inventoryApiHost: string;
    cloudflareAccountHash: string;
    homeUrl: string;
}

export const getRemoteConfig = async (): Promise<EnvConfig | undefined> => {
    const runtimeConfig = useRuntimeConfig();

    let remoteConfig: RemoteEnvConfig | undefined;
    if (ENV_DEV) {
        const endpoint = runtimeConfig.public.remoteConfigEndpoint;
        if (endpoint) {
            const backEnv = useStorage('back-env', 'dev-gamma');

            try {
                remoteConfig = (await (await fetch(endpoint)).json())[backEnv.value];
            } catch {
                console.error('Unable to fetch remote config');
            }
        }
    }

    const authHost = runtimeConfig.public.authHost || remoteConfig?.metaverse?.authHost;
    const authApiHost = runtimeConfig.public.authApiHost || remoteConfig?.metaverse?.authApiHost;
    const achievementsHost = runtimeConfig.public.achievementsHost || remoteConfig?.achievements?.host;
    const premiumStoreHost
        = runtimeConfig.public.premiumStoreHost || remoteConfig?.metaverse?.premiumStoreUrl?.replace('https://', '');
    const inventoryApiHost = runtimeConfig.public.inventoryApiHost || remoteConfig?.metaverse?.inventoryApiHost;
    const cloudflareAccountHash = runtimeConfig.public.cloudflareAccountHash || remoteConfig?.metaverse?.cloudflareAccountHash;
    const homeUrl = runtimeConfig.public.homeUrl || '/';

    if (!authHost || !authApiHost || !achievementsHost || !premiumStoreHost || !inventoryApiHost || !cloudflareAccountHash) {
        console.error('Missing env config values');
        return;
    }

    return { authHost, authApiHost, achievementsHost, premiumStoreHost, inventoryApiHost, cloudflareAccountHash, homeUrl };
};
