import Keycloak from 'keycloak-js';

export const enum KeycloackLoginType {
  emailLogin = 'email_login',
  walletLogin = 'wallet_login',
}

const getKeycloakConfig = (authUrl: string) => ({
    url: authUrl,
    realm: 'quickstart',
    clientId: 'main-client',
});

export class KeycloakProvider {
    private readonly keycloakInstance: Keycloak;

    constructor(authUrl: string) {
        const url = new URL(window.location.href);
        if (url.hash.includes('#error=access_denied')) {
            url.hash = '';
            window.history.replaceState(null, '', url);

            console.error('Auth error: access_denied');
        }

        this.keycloakInstance = new Keycloak(getKeycloakConfig(authUrl));
    }

    get onAuthSuccess(): typeof this.keycloakInstance.onAuthSuccess {
        return this.keycloakInstance.onAuthSuccess;
    }
    set onAuthSuccess(callback: () => void) {
        this.keycloakInstance.onAuthSuccess = callback;
    }

    get onAuthLogout(): typeof this.keycloakInstance.onAuthLogout {
        return this.keycloakInstance.onAuthLogout;
    }
    set onAuthLogout(callback: () => void) {
        this.keycloakInstance.onAuthLogout = callback;
    }

    get accessToken() {
        return this.keycloakInstance.token;
    }

    get accessTokenExpiresIn() {
        const exp = this.keycloakInstance.tokenParsed?.exp;
        if (exp === undefined) {
            return undefined;
        }

        return exp - Date.now() / 1000;
    }

    get blockchainAddress() {
        const blockchainAddress = this.keycloakInstance.tokenParsed?.blockchain_address;
        if (typeof blockchainAddress !== 'string') {
            return undefined;
        }

        return blockchainAddress;
    }

    get isLogged() {
        const noTokenOrExpired = !this.keycloakInstance.token || this.keycloakInstance.isTokenExpired();

        return !noTokenOrExpired;
    }

    async init() {
        try {
            return this.keycloakInstance.init({ onLoad: 'check-sso' });
        }
        catch (e) {
            const url = new URL(window.location.href);
            if (url.hash.includes('#error=login_required')) {
                url.hash = '';
                window.history.replaceState(null, '', url);
            } else {
                window.location.reload.bind(window.location);

                console.error('Auth provider init error:', e);
            }

            return false;
        }
    }

    async login(type: KeycloackLoginType) {
        return this.keycloakInstance.login({ action: type });
    }

    async logout() {
        return this.keycloakInstance.logout();
    }

    updateToken = async (minValidity: number) => {
        if (!this.keycloakInstance.token) {
            this.keycloakInstance.clearToken();
            this.keycloakInstance.onAuthLogout?.();
            return false;
        }

        try {
            const isRefreshed = await this.keycloakInstance.updateToken(minValidity);
            if (!isRefreshed) {
                console.debug('token is valid', this.keycloakInstance.token);

                return isRefreshed;
            }
        } catch (error) {
            if (this.isLogged) {
                return false;
            }

            console.error('Failed to refresh token', error);

            this.keycloakInstance.clearToken();
            this.keycloakInstance.onAuthLogout?.();
            return false;
        }

        console.debug('[KeycloackProvider]: Token refreshed', {
            token: this.keycloakInstance.token,
            refreshToken: this.keycloakInstance.refreshToken,
        });

        return true;
    };
}
