<script setup lang="ts">
const scaleStore = useScaleStore();
const { width, height } = useWindowSize();

throttledWatch(
    () => [width.value, height.value],
    () => {
        scaleStore.viewportWidth = width.value;
        scaleStore.viewportHeight = height.value;
    },
    {
        immediate: true,
        throttle: 100,
    },
);
const nuxtApp = useNuxtApp();

const achievementsStore = useAchievementsStore();

const assetLoading = ref(true);

nuxtApp.hook('page:start', () => {
    assetLoading.value = true;
});
nuxtApp.hook('page:finish', () => {
    assetLoading.value = false;
});

const loading = computed(() => assetLoading.value || achievementsStore.isLoading);
</script>

<template>
    <global-loading :showed="loading" />

    <nuxt-layout>
        <nuxt-page />
    </nuxt-layout>
</template>

<style lang="scss">
* {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
</style>
