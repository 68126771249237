import { KeycloakProvider, KeycloackLoginType as KeycloakLoginType } from '~/services/authService/keycloakProvider';
import { AuthSdkProvider, SdkLoginConfig, SdkBindConfig } from '~/services/authService/sdkProvider';
import eventService from '~/services/eventService';

const TOKEN_REFRESH_INTERVAL_MS = 5000;

export enum AuthType {
    Keycloak = 'keycloak',
    AuthSdk = 'authSdk',
}

export type LoginConfig = {
    type: AuthType.Keycloak;
    loginType: KeycloakLoginType;
} | ({
    type: AuthType.AuthSdk;
} & SdkLoginConfig);

export type BindConfig = ({
    type: AuthType.AuthSdk;
} & SdkBindConfig);

export interface UserInfo {
    username?: string;
    referralCode?: string;
    twitterId?: string;
    telegramId?: string;
    email?: string;
    walletAddresses: string[];
}

export class AuthService {
    public keycloakProvider?: KeycloakProvider;
    public authSdkProvider?: AuthSdkProvider;

    private _isInited = false;

    private refreshTimeout: ReturnType<typeof setTimeout> | null = null;
    private refreshCancelToken = 0;

    get isInited() { return this._isInited; }
    get isLogged() {  return this.keycloakProvider?.isLogged || this.authSdkProvider?.isLogged || false; }
    get accessToken() { return this.keycloakProvider?.accessToken || this.authSdkProvider?.accessToken; }

    get connectedAddresses() { return this.authSdkProvider?.connectedAddresses; }
    get loginAddress() { return this.keycloakProvider?.blockchainAddress || this.authSdkProvider?.loginAddress; }

    get userInfo() {
        return {
            username: this.authSdkProvider?.userInfo?.visibleUsername,
            referralCode: this.authSdkProvider?.userInfo?.refCode,
            twitterId: this.authSdkProvider?.userInfo?.twitterId,
            telegramId: this.authSdkProvider?.userInfo?.telegramId,
            email: this.authSdkProvider?.userInfo?.email,
            walletAddresses: this.authSdkProvider?.userInfo?.walletAddresses ?? [],
        };
    }

    async init({ host, apiHost }: { host?: string; apiHost?: string; }) {
        if (host)
            await this.initKeycloakProvider(this.buildUrl(host, true));

        if (apiHost)
            await this.initAuthSdkProvider(this.buildUrl(`${apiHost}/api`, true));

        this._isInited = true;

        eventService.auth.emit('inited');
    }

    destroy(): void {
        this.stopRefresh();
    }

    async login(config: LoginConfig) {
        if (!this.isInited || this.isLogged) {
            console.error('AuthService is not inited or user is already logged in');
            return;
        }

        console.log(`Login ${config}.`);

        switch (config.type) {
            case AuthType.Keycloak:
                await this.keycloakProvider?.login(config.loginType);
                break;

            case AuthType.AuthSdk:
                await this.authSdkProvider?.login(config);
                break;
        }

        console.log('Login sent.');
    }

    async logout() {
        if (this.authSdkProvider?.isLogged)
            await this.authSdkProvider?.logout();

        if (this.keycloakProvider?.isLogged)
            await this.keycloakProvider?.logout();
    }

    async bind(config: BindConfig) {
        if (!this.isInited || !this.isLogged) {
            console.error('AuthService is not inited or user is not logged in');
            return;
        }

        console.log(`Bind ${config}.`);

        switch (config.type) {
            case AuthType.AuthSdk:
                await this.authSdkProvider?.bind(config);
                break;
        }

        console.log('Bind sent.');
    }

    private async initKeycloakProvider(authUrl: string) {
        if (this.keycloakProvider) return;

        this.keycloakProvider = new KeycloakProvider(authUrl);
        this.keycloakProvider.onAuthSuccess = this.onAuthSuccess;
        this.keycloakProvider.onAuthLogout = this.onAuthLogout;

        await this.keycloakProvider.init();
    }

    private async initAuthSdkProvider(authUrl: string) {
        if (this.authSdkProvider) return;

        this.authSdkProvider = new AuthSdkProvider(authUrl);
        this.authSdkProvider.onAuthSuccess = this.onAuthSuccess;
        this.authSdkProvider.onAuthLogout = this.onAuthLogout;
        this.authSdkProvider.onBindSuccess = this.onBindSuccess;
        this.authSdkProvider.onWalletConnect = this.onWalletConnect;

        await this.authSdkProvider.init();
    }

    private onAuthSuccess = () => {
        eventService.auth.emit('login');
        this.startRefresh();
    };

    private onAuthLogout = () => {
        eventService.auth.emit('logout');
        this.stopRefresh();
    };

    private onBindSuccess = () => {
        eventService.auth.emit('bind');
    };

    private onWalletConnect = () => {
        eventService.auth.emit('connectWallet');
    };

    private startRefresh() {
        this.stopRefresh();

        console.debug('Starting token refresh');

        this.scheduleRefresh();
    }

    private scheduleRefresh() {
        const expiresInMs = this.tokenExiresInMs();
        if (expiresInMs === undefined) {
            console.error('Token expiration refreshInMs is not available');
            return;
        }

        const refreshInMs = expiresInMs - TOKEN_REFRESH_INTERVAL_MS;
        const cancelToken = ++this.refreshCancelToken;

        if (refreshInMs <= 0) {
            console.error('Failed to schedule token refresh. Token is already expired.', { expiresInMs });
            eventService.auth.emit('logout');
            return;
        }

        console.debug('Token will be refreshed in', refreshInMs / 1000, 'seconds');

        this.refreshTimeout = setTimeout(async () => {
            if (!this.refreshTimeout || this.refreshCancelToken !== cancelToken)
                return;

            const isRefreshed = await this.refresh();

            if (isRefreshed)
                this.scheduleRefresh();
            else {
                console.error('Failed to refresh token.', { isRefreshed, refreshInMs });
                eventService.auth.emit('logout');
            }
        }, refreshInMs);
    }

    private async refresh() {
        if (this.keycloakProvider?.isLogged) {
            return await this.keycloakProvider.updateToken(-1);
        }

        if (this.authSdkProvider?.isLogged) {
            return await this.authSdkProvider.refreshSession();
        }

        return false;
    }

    private stopRefresh() {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
            this.refreshTimeout = null;
        }
    }

    // TODO: check
    private tokenExiresInMs() {
        if (!this.accessToken) return undefined;

        const jwt = parseJwt(this.accessToken);

        return jwt.exp * 1000 - Date.now();
    }

    private buildUrl(host: string, useSSL: boolean = true) {
        return `${useSSL ? 'https' : 'http'}://${host}`;
    }
}

export const authService = new AuthService();
